<template>
  <transition name="idleFade">
    <atm-rest-screen v-if="isIdle" />
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import AtmRestScreen from '../pages/pdv/atm/AtmRestScreen.vue'

export default {
  components: {
    AtmRestScreen,
  },
  computed: {
    ...mapGetters('app/idle', ['isIdle']),
  },
}

</script>

<style scoped lang="scss">
/* Fade transition */
.idleFade-enter-active,
.idleFade-leave-active {
  transition: opacity 0.5s ease;
}

.idleFade-enter,
.idleFade-leave-to {
  opacity: 0;
}
</style>
