import FormulateOskTextMask from './FormulateOskTextMask.vue'

export default function VueFormulateOskTextMaskPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateOskTextMask,
    },
    library: {
      'osk-text-mask': {
        classification: 'text',
        component: 'FormulateOskTextMask',
        slotProps: {
          component: ['oskType', 'inputMode'],
        },
      },
    },
  })
}
