import i18n from '@/libs/i18n'

const invoiceDomains = {
  data() {
    return {
      invoiceStockLossesReasonEnum: {
        InternalConsumption: 'InternalConsumption',
        Deviation: 'Deviation',
        Operational: 'Operational',
      },
      invoiceRequestTypeEnum: {
        GENERATE_AND_SEND_INVOICE: 'GENERATE_AND_SEND_INVOICE',
        CONSULT_INVOICE: 'CONSULT_INVOICE',
        CONSULT_PROTOCOL: 'CONSULT_PROTOCOL',
      },
      invoiceEventTypeEnum: {
        CANCEL_INVOICE: 'CANCEL_INVOICE',
        SEND_CORRECTION_LETTER: 'SEND_CORRECTION_LETTER',
      },
      environmentEnum: {
        PRODUCTION: 'PRODUCTION',
        HOMOLOGATION: 'HOMOLOGATION',
      },
      invoiceModelEnum: {
        NFE: 'NFE',
        SAT: 'SAT',
        NFCE: 'NFCE',
      },
      movementTypeEnum: {
        INPUT: 'INPUT',
        OUTPUT: 'OUTPUT',
        INPUT_OUTPUT: 'INPUT_OUTPUT',
      },
      operationTypeEnum: {
        INTERNAL: 'INTERNAL',
        INTERSTATE: 'INTERSTATE',
        EXTERIOR: 'EXTERIOR',
      },
      statusInvoiceEnum: {
        DRAFT: 'DRAFT',
        XML_GENERATED: 'XML_GENERATED',
        SUCCESS_FINISHED: 'SUCCESS_FINISHED',
        CANCELED: 'CANCELED',
        REJECTED: 'REJECTED',
        ISSUED_IN_CONTINGENCY: 'ISSUED_IN_CONTINGENCY',
        INVALIDATED: 'INVALIDATED',
        WAITING_FOR_RESPONSE: 'WAITING_FOR_RESPONSE',
        DENIED: 'DENIED',
      },
      invoicePurposeTypeEnum: {
        NORMAL: 'NORMAL',
        COMPLEMENTARY: 'COMPLEMENTARY',
        ADJUSTMENT: 'ADJUSTMENT',
        DEVOLUTION: 'DEVOLUTION',
      },
      presenceIndicatorEnum: {
        NOT_APPLICABLE: 'NOT_APPLICABLE',
        PRESENTIAL_OPERATION: 'PRESENTIAL_OPERATION',
        INTERNET_OPERATION: 'INTERNET_OPERATION',
        CALLSERVICE_OPERATION: 'CALLSERVICE_OPERATION',
        NFCe_WITH_DELIVERY: 'NFCe_WITH_DELIVERY',
        PRESENTIAL_OPERATION_OUTSIDE_ESTABLISHMENT: 'PRESENTIAL_OPERATION_OUTSIDE_ESTABLISHMENT',
        OTHER: 'OTHER',
      },
      paymentIndicatorEnum: {
        IN_CASH: 'IN_CASH',
        ON_TERM: 'ON_TERM',
        OTHER: 'OTHER',
        WITHOUT_PAYMENT: 'WITHOUT_PAYMENT',
      },
      cfopTypeEnum: {
        NORMAL: 'NORMAL',
        COMPLEMENTARY: 'COMPLEMENTARY',
        ADJUSTMENT: 'ADJUSTMENT',
        DEVOLUTION: 'DEVOLUTION',
      },
      invoiceCategoryEnum: {
        PuchaseOrderReturn: 'PuchaseOrderReturn',
        Sale: 'Sale',
        Transference: 'Transference',
        StockLosses: 'StockLosses',
        ProductBonus: 'ProductBonus',
        StoreLending: 'StoreLending',
        InvoiceFromCoupon: 'InvoiceFromCoupon',
        InvoiceReturnFromCoupon: 'InvoiceReturnFromCoupon',
        InvoiceComplementary: 'InvoiceComplementary',
        SaleFromPdv: 'SaleFromPdv',
      },
      invoiceReceivedStatusEnum: {
        Pending: 'Pending',
        Processed: 'Processed',
        IssuerInvoiceCancelled: 'IssuerInvoiceCancelled',
      },
    }
  },

  computed: {
    invoiceStockLossesReasonLabel() {
      return {
        InternalConsumption: i18n.t('Consumo interno (ex: gelo)'),
        Deviation: i18n.t('Desvio'),
        Operational: i18n.t('Operacional (vencidos ou avariados que não tem troca)'),
      }
    },
    invoiceModelLabel() {
      return {
        NFE: i18n.t('Nota Fiscal Eletrônica'),
        SAT: i18n.t('SAT'),
        NFCE: i18n.t('Nota Fiscal Consumidor Final'),
        NFSE: i18n.t('Nota Fiscal de Serviços'),
      }
    },
    invoiceModelLabelShort() {
      return {
        NFE: i18n.t('NFe'),
        SAT: i18n.t('SAT/MFe'),
        NFCE: i18n.t('NFCe'),
      }
    },
    movementTypeLabel() {
      return {
        INPUT: i18n.t('Entrada'),
        OUTPUT: i18n.t('Saída'),
        INPUT_OUTPUT: i18n.t('Entrada/Saída'),
      }
    },
    operationTypeLabel() {
      return {
        INTERNAL: i18n.t('Interna'),
        INTERSTATE: i18n.t('Interestadual'),
        EXTERIOR: i18n.t('Exterior'),
      }
    },
    statusInvoiceLabel() {
      return {
        DRAFT: i18n.t('Em digitação'),
        XML_GENERATED: i18n.t('Xml Gerado'),
        SUCCESS_FINISHED: i18n.t('Finalizado com sucesso'),
        ISSUED_IN_CONTINGENCY: i18n.t('Emitida em contingência'),
        CANCELED: i18n.t('Cancelado'),
        REJECTED: i18n.t('Rejeitado'),
        WAITING_FOR_RESPONSE: i18n.t('Aguardando resposta'),
        INVALIDATED: i18n.t('Inutilizada'),
        DENIED: i18n.t('Denegada'),
      }
    },
    invoiceReceivedReasonLabel() {
      return {
        SalableProducts: i18n.t('Produtos vendáveis'),
        Lending: i18n.t('Comodatos'),
        FixedAssets: i18n.t('Bens permanentes'),
        Inputs: i18n.t('Insumos'),
        Services: i18n.t('Serviços'),
        OngoingServices: i18n.t('Serviços contínuos'),
        NotRecognized: i18n.t('Não reconhecida'),
      }
    },
    invoicePurposeTypeLabel() {
      return {
        NORMAL: i18n.t('Normal'),
        COMPLEMENTARY: i18n.t('Complementar'),
        ADJUSTMENT: i18n.t('Correção'),
        DEVOLUTION: i18n.t('Devolução'),
      }
    },
    presenceIndicatorLabel() {
      return {
        NOT_APPLICABLE: i18n.t('Não aplicável'),
        PRESENTIAL_OPERATION: i18n.t('Presencial'),
        INTERNET_OPERATION: i18n.t('Internet'),
        CALLSERVICE_OPERATION: i18n.t('Serviço de chamada'),
        NFCe_WITH_DELIVERY: i18n.t('Nota Fiscal com entrega'),
        PRESENTIAL_OPERATION_OUTSIDE_ESTABLISHMENT: i18n.t('Presencial fora do estabelecimento'),
        OTHER: i18n.t('Outros'),
      }
    },
    paymentIndicatorLabel() {
      return {
        IN_CASH: i18n.t('A vista'),
        ON_TERM: i18n.t('A prazo'),
        OTHER: i18n.t('Outro'),
        WITHOUT_PAYMENT: i18n.t('Sem pagamento'),
      }
    },
    cfopTypeLabel() {
      return {
        NORMAL: i18n.t('CFOP.NORMAL'),
        COMPLEMENTARY: i18n.t('CFOP.COMPLEMENTARY'),
        ADJUSTMENT: i18n.t('CFOP.ADJUSTMENT'),
        DEVOLUTION: i18n.t('CFOP.DEVOLUTION'),
      }
    },
    invoiceCategoryToRadio() {
      return {
        Sale: this.$t('Venda'),
        Transference: this.$t('Transferência'),
        StockLosses: this.$t('Baixa de estoque'),
        ProductBonus: this.$t('Bonificação'),
        StoreLending: this.$t('Comodato'),
      }
    },
    invoiceCategoryLabel() {
      return {
        PuchaseOrderReturn: this.$t('Devolução de compra'),
        Sale: this.$t('Venda'),
        SaleFromPdv: this.$t('Venda emitida pelo PDV'),
        Transference: this.$t('Transferência'),
        StockLosses: this.$t('Baixa de estoque'),
        ProductBonus: this.$t('Bonificação'),
        StoreLending: this.$t('Comodato'),
        InvoiceFromCoupon: this.$t('Venda a partir do Cupom Fiscal'),
        InvoiceReturnFromCoupon: this.$t('Devolução a partir do Cupom Fiscal'),
        InvoiceComplementary: this.$t('Complementar'),
      }
    },
    invoiceReceivedStatusLabel() {
      return {
        [this.invoiceReceivedStatusEnum.Pending]: this.$t(
          `InvoiceReceived.Status.${this.invoiceReceivedStatusEnum.Pending}`
        ),
        [this.invoiceReceivedStatusEnum.Processed]: this.$t(
          `InvoiceReceived.Status.${this.invoiceReceivedStatusEnum.Processed}`
        ),
        [this.invoiceReceivedStatusEnum.IssuerInvoiceCancelled]: this.$t(
          `InvoiceReceived.Status.${this.invoiceReceivedStatusEnum.IssuerInvoiceCancelled}`
        ),
      }
    },
  },

  methods: {
    invoiceStockLossesReasonOptions() {
      return [
        {
          value: this.invoiceStockLossesReasonEnum.InternalConsumption,
          label: this.invoiceStockLossesReasonLabel.InternalConsumption,
        },
        {
          value: this.invoiceStockLossesReasonEnum.Deviation,
          label: this.invoiceStockLossesReasonLabel.Deviation,
        },
        {
          value: this.invoiceStockLossesReasonEnum.Operational,
          label: this.invoiceStockLossesReasonLabel.Operational,
        },
      ]
    },
    invoiceModelOptions() {
      return [
        { value: 'NFE', label: this.invoiceModelLabel.NFE },
        { value: 'SAT', label: this.invoiceModelLabel.SAT },
        {
          value: 'NFCE',
          label: this.invoiceModelLabel.NFCE,
        },
        {
          value: 'NFSE',
          label: this.invoiceModelLabel.NFSE,
        },
      ]
    },
    invoiceReceivedReasonOptions() {
      return [
        { value: 'NotRecognized', label: this.invoiceReceivedReasonLabel.NotRecognized },
        { value: 'SalableProducts', label: this.invoiceReceivedReasonLabel.SalableProducts },
        { value: 'Lending', label: this.invoiceReceivedReasonLabel.Lending },
        { value: 'FixedAssets', label: this.invoiceReceivedReasonLabel.FixedAssets },
        { value: 'Inputs', label: this.invoiceReceivedReasonLabel.Inputs },
        { value: 'Services', label: this.invoiceReceivedReasonLabel.Services },
        { value: 'OngoingServices', label: this.invoiceReceivedReasonLabel.OngoingServices },
      ]
    },
    movementTypeOptions() {
      return [
        { value: 'INPUT', label: this.movementTypeLabel.INPUT },
        { value: 'OUTPUT', label: this.movementTypeLabel.OUTPUT },
      ]
    },
    allMovementTypeOptions() {
      return [
        { value: 'INPUT', label: this.movementTypeLabel.INPUT },
        { value: 'OUTPUT', label: this.movementTypeLabel.OUTPUT },
        { value: 'INPUT_OUTPUT', label: this.movementTypeLabel.INPUT_OUTPUT },
      ]
    },
    operationTypeOptions() {
      return [
        { value: 'INTERNAL', label: this.operationTypeLabel.INTERNAL },
        { value: 'INTERSTATE', label: this.operationTypeLabel.INTERSTATE },
        { value: 'EXTERIOR', label: this.operationTypeLabel.EXTERIOR },
      ]
    },
    statusInvoiceOptions() {
      return [
        { value: 'DRAFT', label: this.statusInvoiceLabel.DRAFT },
        { value: 'XML_GENERATED', label: this.statusInvoiceLabel.XML_GENERATED },
        { value: 'SUCCESS_FINISHED', label: this.statusInvoiceLabel.SUCCESS_FINISHED },
        { value: 'CANCELED', label: this.statusInvoiceLabel.CANCELED },
        { value: 'REJECTED', label: this.statusInvoiceLabel.REJECTED },
        { value: 'INVALIDATED', label: this.statusInvoiceLabel.INVALIDATED },
        { value: 'WAITING_FOR_RESPONSE', label: this.statusInvoiceLabel.WAITING_FOR_RESPONSE },
        { value: 'DENIED', label: this.statusInvoiceLabel.DENIED },
      ]
    },
    invoicePurposeTypeOptions() {
      return [
        { value: 'NORMAL', label: this.invoicePurposeTypeLabel.NORMAL },
        { value: 'COMPLEMENTARY', label: this.invoicePurposeTypeLabel.COMPLEMENTARY },
        { value: 'ADJUSTMENT', label: this.invoicePurposeTypeLabel.ADJUSTMENT },
        { value: 'DEVOLUTION', label: this.invoicePurposeTypeLabel.DEVOLUTION },
      ]
    },
    presenceIndicatorOptions() {
      return [
        { value: 'NOT_APPLICABLE', label: this.presenceIndicatorLabel.NOT_APPLICABLE },
        { value: 'PRESENTIAL_OPERATION', label: this.presenceIndicatorLabel.PRESENTIAL_OPERATION },
        { value: 'INTERNET_OPERATION', label: this.presenceIndicatorLabel.INTERNET_OPERATION },
        {
          value: 'CALLSERVICE_OPERATION',
          label: this.presenceIndicatorLabel.CALLSERVICE_OPERATION,
        },
        { value: 'NFCe_WITH_DELIVERY', label: this.presenceIndicatorLabel.NFCe_WITH_DELIVERY },
        {
          value: 'PRESENTIAL_OPERATION_OUTSIDE_ESTABLISHMENT',
          label: this.presenceIndicatorLabel.PRESENTIAL_OPERATION_OUTSIDE_ESTABLISHMENT,
        },
        { value: 'OTHER', label: this.presenceIndicatorLabel.OTHER },
      ]
    },
    paymentIndicatorOptions() {
      return [
        { value: 'IN_CASH', label: this.paymentIndicatorLabel.IN_CASH },
        { value: 'ON_TERM', label: this.paymentIndicatorLabel.ON_TERM },
        { value: 'OTHER', label: this.paymentIndicatorLabel.OTHER },
        { value: 'WITHOUT_PAYMENT', label: this.paymentIndicatorLabel.WITHOUT_PAYMENT },
      ]
    },

    cfopTypeOptions() {
      return [
        { value: 'NORMAL', label: this.cfopTypeLabel.NORMAL },
        { value: 'COMPLEMENTARY', label: this.cfopTypeLabel.COMPLEMENTARY },
        { value: 'ADJUSTMENT', label: this.cfopTypeLabel.ADJUSTMENT },
        { value: 'DEVOLUTION', label: this.cfopTypeLabel.DEVOLUTION },
      ]
    },

    invoiceCategoryOptions() {
      return [
        {
          value: this.invoiceCategoryEnum.PuchaseOrderReturn,
          label: this.invoiceCategoryLabel.PuchaseOrderReturn,
        },
        { value: this.invoiceCategoryEnum.Sale, label: this.invoiceCategoryLabel.Sale },
        {
          value: this.invoiceCategoryEnum.SaleFromPdv,
          label: this.invoiceCategoryLabel.SaleFromPdv,
        },
        {
          value: this.invoiceCategoryEnum.Transference,
          label: this.invoiceCategoryLabel.Transference,
        },
        {
          value: this.invoiceCategoryEnum.StockLosses,
          label: this.invoiceCategoryLabel.StockLosses,
        },
        {
          value: this.invoiceCategoryEnum.ProductBonus,
          label: this.invoiceCategoryLabel.ProductBonus,
        },
        {
          value: this.invoiceCategoryEnum.StoreLending,
          label: this.invoiceCategoryLabel.StoreLending,
        },
        {
          value: this.invoiceCategoryEnum.InvoiceFromCoupon,
          label: this.invoiceCategoryLabel.InvoiceFromCoupon,
        },
        {
          value: this.invoiceCategoryEnum.InvoiceReturnFromCoupon,
          label: this.invoiceCategoryLabel.InvoiceReturnFromCoupon,
        },
        {
          value: this.invoiceCategoryEnum.InvoiceComplementary,
          label: this.invoiceCategoryLabel.InvoiceComplementary,
        },
      ]
    },
    invoiceReceivedStatusOptions() {
      return [
        {
          value: this.invoiceReceivedStatusEnum.Pending,
          label: this.invoiceReceivedStatusLabel.Pending,
          classStyle: 'secondary',
        },
        {
          value: this.invoiceReceivedStatusEnum.Processed,
          label: this.invoiceReceivedStatusLabel.Processed,
          classStyle: 'success',
        },
        {
          value: this.invoiceReceivedStatusEnum.IssuerInvoiceCancelled,
          label: this.invoiceReceivedStatusLabel.IssuerInvoiceCancelled,
          classStyle: 'danger',
        },
      ]
    },
  },
}

export default invoiceDomains
