/* eslint-disable import/prefer-default-export */

import axios from '@axios'
import { v4 as uuidV4 } from 'uuid'

const fetchDomainData = (apiPath, currentData, { params } = {}) => {
  if (currentData?.length) {
    return new Promise(resolve =>
      resolve({
        data: {
          results: currentData,
        },
      })
    )
  }
  return axios.get(apiPath, { params: { pageSize: 0, pageIndex: 0, ...params } })
}

/**
 * Inserção de objeto dentro da lista passada.
 *
 * @param {Object[]} list
 * @param {Object} item
 * @returns `list` com o `item` adicionado, contendo campo `localId`.
 */
const localListAddItem = (list, item, isAddReverse = false) => {
  const itemList = [...list]
  let localId = item?.localId
  if (!localId) localId = uuidV4()

  if (isAddReverse) itemList.unshift({ ...item, localId })
  else itemList.push({ ...item, localId })
  return itemList
}

/**
 * Alteração de 1 objeto dentro da lista pelo `item`. Alteração é filtrado pelo `id` ou `localId` dentro de `item`
 *
 * @param {Object[]} list  objetos dentro do `list` devem possuir `id` ou `localId`
 * @param {Object} item deve possuir `id` ou `localId`
 * @param {Number} item.id
 * @param {String} item.localId
 * @returns lista atualizada com o item passado
 */
const localListUpdateItem = (list, item) => {
  const itemList = [...list]

  const indexFound = itemList.findIndex(
    v => (item.id && v.id === item.id) || (item.localId && v.localId === item.localId)
  )

  itemList.splice(indexFound, 1, item)
  return itemList
}

/**
 * Remoção de 1 objeto da lista, se o objeto possui `id` faz delete lógico, se possui `localId` faz delete físico
 *
 * @param {Object[]} list objetos dentro do list devem possuir `id` ou `localId`
 * @param {number | string} idOrLocalId
 * @returns lista sem o objeto que possuia o `idOrLoacalId` correspondente
 */
const localListDeleteItem = (list, idOrLocalId, isHardDelete = false) => {
  const itemList = [...list]
  const indexFound = itemList.findIndex(v => v.id === idOrLocalId || v.localId === idOrLocalId)

  if (indexFound < 0) return itemList

  if (isHardDelete) {
    itemList.splice(indexFound, 1)
    return itemList
  }

  if (itemList[indexFound]?.id) {
    itemList[indexFound].isDeleted = true
  } else if (itemList[indexFound]?.localId) {
    itemList.splice(indexFound, 1)
  }

  return itemList
}

export { fetchDomainData, localListAddItem, localListUpdateItem, localListDeleteItem }
