import jwt from 'jsonwebtoken'
/* eslint-disable import/no-cycle */
import useJwt from '@/auth/jwt/useJwt'
// import { setCookie } from 'tiny-cookie'
import { defineAbility } from '@casl/ability'
// import store from '@/store'
import { nowUTC } from '@/utils/dateUtils'
import isElectron from 'is-electron'

export const getPermissions = () => {
  const permissions = localStorage.getItem('permissions')
  if (permissions) {
    return JSON.parse(permissions)
  }
  return undefined
}

export const hasPermissions = (...permissions) => {
  const userPermissions = getPermissions()

  if (!userPermissions) return false

  // eslint-disable-next-line no-restricted-syntax
  for (const p in permissions) {
    if (!userPermissions.includes(permissions[p])) return false
  }

  return true
}

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  const token = useJwt.getToken()
  if (!token) {
    return false
  }

  try {
    // debugger
    const decoded = jwt.decode(token)

    return (
      (!decoded.exp || nowUTC().getTime() / 1000 < decoded.exp) && getPermissions() !== undefined
    )
  } catch {
    return false
  }
}

export const isRequiredChangePassword = () => {
  const token = useJwt.getToken()
  const decoded = jwt.decode(token)

  if (!decoded || (!decoded.RequiredChangePassword && !decoded.requiredChangePassword)) return false
  return (decoded.RequiredChangePassword || decoded.requiredChangePassword).toLowerCase() === 'true'
}

export const isUserAllowedAllStores = () => {
  const token = useJwt.getToken()
  const decoded = jwt.decode(token)

  return (decoded.AllowedAllStores || decoded.allowedAllStores).toLowerCase() === 'true'
}

export const isUserAllowedStore = storeId => {
  const token = useJwt.getToken()
  const decoded = jwt.decode(token)

  return isUserAllowedAllStores() || decoded.AllowedStores.includes(storeId)
}

export const getAbilityFromPermissions = () => {
  const token = useJwt.getToken()
  const decoded = jwt.decode(token)
  const permissions = getPermissions()
  if (!decoded || !permissions) {
    return defineAbility(can => {
      can('read', 'Auth')
    })
  }
  // permissions = decoded.perfilAcesso.split('|')
  const abilityArray = []
  permissions.forEach(permission => {
    const [resource, action] = permission.split('.')
    abilityArray.push({ action, subject: resource })
  })
  // console.log(abilityArray)
  return defineAbility(can => {
    can('read', 'Auth')
    abilityArray.forEach(ability => can(ability.action, ability.subject))
  })
}

/* eslint-disable eqeqeq */
export const getUserData = () =>
  localStorage.getItem('userData') != 'undefined'
    ? JSON.parse(localStorage.getItem('userData'))
    : undefined

export const getAbility = () => getAbilityFromPermissions()

// const saveJwtToken = token => {
//   localStorage.setItem('token', token)
// }

// const saveRefreshToken = token => {
//   setCookie('refreshToken', token, { path: '/' })
// }

// const saveTokens = (jwtToken, refreshToken) => {
//   saveJwtToken(jwtToken)
//   saveRefreshToken(refreshToken)
// }

// export const setCookiesLogin = cookies => {
//   if (cookies) {
//     setCookie('hubsessionid', cookies.hubsessionid, { path: '/' })
//     saveTokens(cookies.token, cookies.refreshToken)
//   }
// }

export const hasErpAccess = () => {
  const permissions = getAbilityFromPermissions()
  return permissions.can('Access', 'ERP')
}

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = () => {
  if (isUserLoggedIn()) {
    if (isElectron() && !hasErpAccess()) {
      return { name: 'pdv-pay-box-open' }
    }
    return { name: 'home' }
  }
  return { name: 'auth-login' }
}
