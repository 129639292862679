import {
  address,
  addressUtils,
  contactUtils,
  deliveryDomain,
  discountTypes,
  payBoxUtils,
  productDomains,
  stringUtils,
} from '@/mixins'
import { localListAddItem, localListDeleteItem, localListUpdateItem } from '@/store/utils'
import { roundDecimal } from '@/utils/number-utils'
import axios from '@axios'
import _ from 'lodash'

const getInitialState = () => ({
  quotation: {
    id: null,
    storeId: null,
    store: null,
    customerId: null,
    customer: null,
    deliveryAddress: address.data().address,
    deliveryFeeType: null,
    deliveryTaxDistance: null,
    deliveryTaxUnitValue: null,
    shippingFee: null,
    shippingFeePerKilometer: null,
    discountType: discountTypes.computed.discountTypesEnum().VALUE,
    discount: 0,
    discountAuth: null,
    priceTableId: '1',
    products: [],
    addressSelected: null,
    isDelivery: false,
    isConsigned: false,
  },
  deliveryData: {
    deliveryFeeType: deliveryDomain.data().deliveryFeeEnum.SINGLE_FEE,
    distanceValue: null,
    shippingFee: 0,
    shippingFeePerKilometer: 0,
    deliveryTax: 0,
    discount: 0,
  },
})

export const getInitialItemForm = () => ({
  id: null,
  localId: null,
  productKitId: null,
  isKit: false,
  skuProduct: null,
  quantity: 1,
  maxQuantity: -1,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  promotionId: null,
  unitDiscount: 0,
  unitDiscountAuth: null,
  isDeleted: false,
  subtotal: 0,
  total: 0,
  totalDiscount: 0,
  priceTable: { id: null },
  lendingProducts: [],
  kitItems: [],
  classification: null,
  hasColdOption: false,
})

export const calculatePrice = item => {
  const { skuProduct, unitDiscount, discountType, quantity, discountPriceTable } = item
  const price = skuProduct?.price || 0

  const { priceInfo, netValue } = payBoxUtils.calculateItemPrice({
    price,
    originalPrice: skuProduct?.originalPrice,
    discountType,
    unitDiscount,
    quantity,
    priceTableDiscount: discountPriceTable,
    promotionId: skuProduct?.promotionId || null,
    tieredPrices: skuProduct?.tieredPrices,
  })

  return {
    subtotal: priceInfo.grossValue,
    total: netValue,
    totalDiscount: priceInfo.discountTotal,
    priceInfo,
  }
}

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    getProducts(state) {
      const itemsProduct = state.quotation.products
        .filter(p => !p.isDeleted)
        .filter(p => !p.skuProduct.productKitId)
        .map(p => ({
          ...p,
          _showDetails: !!p.lendingProducts || p.kitItems?.length > 0,
        }))

      return itemsProduct
    },

    getCustomerAddressOptions(state) {
      return addressUtils.addressOptionsWithOther(state.quotation.customer?.addresses)
    },

    getCustomerTelephone(state) {
      return contactUtils.getPricipalMobileAndLandline(state.quotation.customer?.telephones)
    },

    getQuotationTotalProducts(state) {
      const { discountType, discount, products, isDelivery } = state.quotation
      const totalProducts = products.reduce((prev, p) => prev + p.total, 0)
      const totalDelivery = isDelivery ? state.deliveryData.deliveryTax : 0
      const discountValue = payBoxUtils.getDiscountValue({
        discountType,
        discountValue: discount,
        totalValue: totalProducts,
      })
      return totalProducts + totalDelivery - discountValue
    },

    getQuotationTotalDiscount(state) {
      const { products, discountType, discount } = state.quotation
      const totalProducts = products.reduce((prev, p) => prev + p.total, 0)
      const totalProductDiscount = products.reduce((prev, p) => prev + p.totalDiscount, 0)
      const discountValue = payBoxUtils.getDiscountValue({
        discountType,
        discountValue: discount,
        totalValue: totalProducts,
      })
      return totalProductDiscount + discountValue
    },

    hasDoubleDelivery(state) {
      const { isConsigned, isDelivery } = state.quotation
      if (!isDelivery) return false
      if (isConsigned) return true

      const { RENT } = productDomains.computed.productClassificationsEnum()
      const kitItems = state.quotation.products.flatMap(p => p.kitItems || [])
      const allProducts = kitItems.concat(state.quotation.products)
      const hasLendingOrRent = allProducts.some(
        p => p.lendingProducts.length > 0 || p.skuProduct.classification === RENT
      )
      return hasLendingOrRent
    },
  },

  mutations: {
    SET_QUOTATION(state, { quotation }) {
      state.quotation = quotation
    },
    SET_QUOTATION_STORE_ID(state, { storeId }) {
      state.quotation.storeId = storeId
    },
    SET_QUOTATION_STORE(state, { store }) {
      state.quotation.store = store
    },
    SET_QUOTATION_CUSTOMER(state, { customer }) {
      const { getStringOrNull } = stringUtils.methods
      state.quotation.customerId = getStringOrNull(customer?.id)
      state.quotation.customer = customer
    },
    SET_QUOTATION_PRICE_TABLE_ID(state, { priceTableId }) {
      const { getStringOrNull } = stringUtils.methods
      state.quotation.priceTableId = getStringOrNull(priceTableId) || '1'
    },
    SET_PRODUCTS(state, { products }) {
      state.quotation.products = products
    },
    SET_DELIVERY_TAX(state, deliveryTax) {
      state.deliveryData.deliveryTax = deliveryTax
    },
    SET_DELIVERY_DATA(state, deliveryData) {
      state.deliveryData = deliveryData
    },

    SET_DELIVERY_ADDRESS(state, { addressData }) {
      state.quotation.deliveryAddress = addressData || getInitialState().quotation.deliveryAddress
    },

    CLEAN_STATE(state) {
      const { quotation, deliveryData } = getInitialState()
      state.quotation = quotation
      state.deliveryData = deliveryData
    },

    CLEAN_DELIVERY_ADDRESS(state) {
      const { deliveryAddress } = getInitialState().quotation
      state.quotation.deliveryAddress = deliveryAddress
    },

    CLEAN_INPUTS_AUTH(state) {
      const { discount, discountAuth } = getInitialState().quotation
      const { unitDiscount, unitDiscountAuth } = getInitialItemForm()
      state.quotation.discountAuth = discountAuth
      state.quotation.discount = discount
      state.quotation.products = state.quotation.products.map(p => ({
        ...p,
        unitDiscount,
        unitDiscountAuth,
      }))
    },
    CLEAN_CUSTOMER(state) {
      const { customer, customerId } = getInitialState().quotation
      state.quotation.customerId = customerId
      state.quotation.customer = customer
    },
  },

  actions: {
    async fetchQuotationById({ commit, dispatch }, id) {
      commit('CLEAN_STATE')
      const { data } = await axios.get(`/api/sales/quotations/${id}`)
      const initialItem = getInitialItemForm()
      const initialState = getInitialState()
      const { LENDING } = productDomains.computed.productClassificationsEnum()
      const { FEE_PER_KILOMETER, SINGLE_FEE } = deliveryDomain.data().deliveryFeeEnum

      const lendingProducts = data.items.filter(item => item.classification === LENDING)
      const products = _.sortBy(data.items, ['id'])
        .filter(item => !item.productKitId && !item.skuAssociatedId)
        .map(item => {
          const dataLp = lendingProducts.find(lp => lp.quotationItemAssociatedId === item.id)
          let lendingList = []

          if (dataLp) {
            lendingList = [
              {
                ...initialItem,
                id: dataLp.id,
                skuProduct: {
                  ...dataLp.sku,
                  skuId: dataLp.sku.id,
                  contractualFine: dataLp.contractualFine,
                  classification: dataLp.classification,
                },
                skuAssociatedId: dataLp.skuAssociatedId,
                quotationItemAssociatedId: dataLp.quotationItemAssociatedId,
                priceTable: item.priceTable,
                price: dataLp.unitValue,
                productKitId: dataLp.productKitId,
                quantity: dataLp.quantity,
                discountType: dataLp.discountType,
                unitDiscount: dataLp.unitDiscount,
                classification: dataLp.classification,
              },
            ]
          }

          let itemFormated = {
            ...initialItem,
            id: item.id,
            classification: item.classification,
            contractualFine: item.contractualFine,
            skuProduct: {
              ...item.sku,
              skuId: item.sku.id,
              classification: item.classification,
              contractualFine: item.contractualFine,
              label: item.sku.name,
              price: item.unitValue,
              promotionId: item.promotionId,
            },
            promotionId: item.promotionId,
            priceTable: item.priceTable,
            lendingProducts: lendingList,
            productKitId: item.productKitId,
            quantity: item.quantity,
            discountType: item.discountType,
            unitDiscount: item.unitDiscount,
            discountPriceTable: item.discountPriceTable,
          }

          itemFormated = { ...itemFormated, ...calculatePrice(itemFormated) }
          itemFormated.priceInfo.skuTieredPriceId = item.skuTieredPriceId
          return itemFormated
        })

      const kitItems = data.itemKits.map(kit => {
        const itemsInKitFormated = data.items
          .filter(i => i.productKitId === kit.productKit.id)
          .map(item => {
            const itemConfigQuantity = item.quantity / (kit.quantity || 1)

            const itemFormated = {
              ...initialItem,
              classification: item.classification,
              contractualFine: item.contractualFine,
              skuProduct: {
                ...item.sku,
                skuId: item.sku.id,
                classification: item.classification,
                contractualFine: item.contractualFine,
                quantity: itemConfigQuantity,
                promotionId: item.promotionId,
              },
              id: item.id,
              promotionId: item.promotionId,
              quotationItemAssociatedId: item.quotationItemAssociatedId,
              priceTable: item.priceTable,
              discountPriceTable: item.discountPriceTable,
              price: item.unitValue,
              productKitId: item.productKitId,
              quantity: item.quantity * kit.quantity,
              discountType: item.discountType,
              unitDiscount: item.unitDiscount,
            }

            const { priceInfo: lendingPriceInfo, netValue: lendingNetValue } =
              payBoxUtils.calculateItemPrice({
                price: itemFormated.price,
                discountType: itemFormated.discountType,
                quantity: itemFormated.quantity,
                unitDiscount: itemFormated.unitDiscount,
                priceTableDiscount: itemFormated.discountPriceTable,
              })

            return {
              ...itemFormated,
              subtotal: lendingPriceInfo.grossValue,
              total: lendingNetValue,
              totalDiscount: lendingPriceInfo.discountTotal,
            }
          })

        // cenário: itens do kit possuem comodato associado a outro dentro do kit
        const kitItemsAssociated = itemsInKitFormated.filter(
          kia => kia.quotationItemAssociatedId != null
        )
        const itemsInKit = itemsInKitFormated
          .filter(ki => ki.quotationItemAssociatedId == null)
          .map(ki => {
            const associatedOfThisKitItem = kitItemsAssociated.find(
              kia => kia.quotationItemAssociatedId === ki.id
            )
            if (associatedOfThisKitItem) {
              return {
                ...ki,
                lendingProducts: [associatedOfThisKitItem],
              }
            }

            return ki
          })

        const kitItem = {
          ...initialItem,
          skuProduct: {
            ...kit.productKit,
            label: kit.productKit.name,
            price: kit.unitValue,
          },
          quantity: kit.quantity,
          productKitId: kit.productKit.id,
          kitItems: itemsInKit,
        }

        return { ...kitItem, ...calculatePrice(kitItem) }
      })

      let delivery = {
        deliveryAddress: initialState.quotation.deliveryAddress,
        addressSelected: initialState.quotation.addressSelected,
      }

      const shippingValues = {
        shippingFee: data.deliveryFeeType === SINGLE_FEE ? data.deliveryTaxUnitValue : null,
        shippingFeePerKilometer:
          data.deliveryFeeType === FEE_PER_KILOMETER ? data.deliveryTaxUnitValue : null,
      }
      const deliveryData = {
        ...initialState.deliveryData,
        deliveryFeeType: data.deliveryFeeType || initialState.deliveryData.deliveryFeeType,
        distanceValue: data.deliveryTaxDistance || 0,
        deliveryTax: data.deliveryTax,
        ...shippingValues,
      }

      if (data.delivery) {
        const addressLabel = address.methods.formatAddress(data.deliveryAddress)
        const hasInAddressOptions = data.customer.addresses
          .map(ad => address.methods.formatAddress(ad))
          .findIndex(ad => ad === addressLabel)

        const addressSelected =
          hasInAddressOptions > -1
            ? addressUtils.makeAddressOption(data.deliveryAddress)
            : addressUtils.otherAddress()

        delivery = {
          deliveryAddress: data.deliveryAddress,
          addressSelected,
          deliveryTax: data.deliveryTax,
        }
      }

      const quotation = {
        id: data.id,
        storeId: String(data.store.id),
        customerId: stringUtils.methods.getStringOrNull(data.customer?.id),
        customer: {
          ...data.customer,
          label: data.customer.name,
        },
        discountType: data.discountType,
        discount: data.discount,
        isConsigned: data.consigned,
        priceTableId: String(data.priceTable.id),
        isDelivery: data.delivery,
        products: [...products, ...kitItems],
        ...delivery,
        ...shippingValues,
      }

      commit('SET_QUOTATION', { quotation })
      commit('SET_DELIVERY_TAX', data.deliveryTax)
      commit('SET_DELIVERY_DATA', deliveryData)
      await dispatch('fetchStoreById', { storeId: quotation.storeId })
    },
    async fetchStoreById({ commit, dispatch }, { storeId }) {
      const { data } = await axios.get(`/api/stores/${storeId}`)
      commit('SET_QUOTATION_STORE', { store: data })
      await dispatch('reloadDeliveryData')
    },
    async fetchCustomerById({ commit, state, dispatch }, { customerId }) {
      if (state.quotation.customer?.id?.toString() === customerId) return

      const { data } = await axios.get(`/api/customers/${customerId}/read-only`)
      commit('SET_QUOTATION_CUSTOMER', { customer: data })

      if (!state.quotation.id) {
        const validPriceTable = await dispatch(
          'common/priceTables/getPriceTableActiveOrDefaultId',
          { priceTableId: data?.priceTableId },
          { root: true }
        )
        commit('SET_QUOTATION_PRICE_TABLE_ID', { priceTableId: validPriceTable.id })
      }
    },

    setQuotationStoreId({ commit }, { storeId }) {
      commit('SET_QUOTATION_STORE_ID', { storeId })
    },
    setDeliveryAddress({ commit }, { addressData }) {
      commit('SET_DELIVERY_ADDRESS', { addressData })
    },

    async addProduct({ commit, state, dispatch }, { formData }) {
      const data = {
        ...formData,
        ...calculatePrice(formData),
      }
      const itemList = localListAddItem(state.quotation.products, data)
      commit('SET_PRODUCTS', { products: itemList })
      await dispatch('updateDeliveryTax')
    },
    updateProduct({ commit, state }, { formData }) {
      const data = {
        ...formData,
        ...calculatePrice(formData),
      }
      const itemList = localListUpdateItem(state.quotation.products, data)
      commit('SET_PRODUCTS', { products: itemList })
    },
    async removeProduct({ commit, state, dispatch }, { id }) {
      if (!id) throw new Error('Item sem id')
      const itemList = localListDeleteItem(state.quotation.products, id)

      commit('SET_PRODUCTS', { products: itemList })
      await dispatch('updateDeliveryTax')
    },

    async updateProductPrices({ state, commit }) {
      const { storeId, products, priceTableId } = state.quotation

      if (!storeId) return

      // TODO usar endpoint que mande lista de ean ao inves de fazer varias requests
      const productsPromise = products.map(async p => {
        const { data } = await axios.get(
          `/api/sales/pay-box-sku/store/${storeId}/ean/${p.skuProduct.ean}`,
          {
            params: {
              priceTableId,
            },
          }
        )
        const product = {
          ...p,
          skuProduct: { ...data, label: data.name },
          priceTable: { ...p.priceTable, id: priceTableId },
          discountPriceTable: data.priceTable?.discount || 0,
        }

        return { ...product, ...calculatePrice(product) }
      })

      const productsUpdated = await Promise.all(productsPromise)

      commit('SET_PRODUCTS', { products: productsUpdated })
    },

    reloadDeliveryData({ commit, state }) {
      const {
        shippingFee: storeShippingFee,
        shippingFeePerKilometer: storeShippingFeePerKilometer,
      } = state.quotation.store.shippingFees
      commit('SET_DELIVERY_DATA', {
        ...state.deliveryData,
        shippingFee: state.quotation.shippingFee ?? storeShippingFee,
        shippingFeePerKilometer:
          state.quotation.shippingFeePerKilometer ?? storeShippingFeePerKilometer,
      })
    },
    updateDeliveryTax({ state, commit, getters }) {
      const { FEE_PER_KILOMETER } = deliveryDomain.data().deliveryFeeEnum
      const { deliveryFeeType, distanceValue, shippingFee, shippingFeePerKilometer } =
        state.deliveryData
      let totalTax = shippingFee

      if (deliveryFeeType === FEE_PER_KILOMETER) {
        totalTax = roundDecimal(shippingFeePerKilometer * (distanceValue || 0))
      }

      if (getters.hasDoubleDelivery) {
        totalTax *= 2
      }

      commit('SET_DELIVERY_TAX', totalTax)
    },

    async saveQuotation({ state }) {
      const {
        id,
        storeId,
        customer,
        priceTableId,
        products,
        discountType,
        discount,
        isDelivery,
        isConsigned,
        deliveryAddress,
      } = state.quotation
      const { deliveryData } = state

      const kit = products.filter(i => i.kitItems?.length > 0)
      const items = products
        .map(item => {
          const { skuProduct: itemSkuProduct } = item
          if (item.kitItems.length > 0) {
            return item.kitItems
              .map(kitItem => {
                const saleKitItem = {
                  skuId: kitItem.skuProduct.id,
                  productKitId: itemSkuProduct.id,
                  quantity: kitItem.quantity,
                  unitValue: kitItem.price,
                  priceTableId,
                  // discountPriceTable: 0,
                  discountType: kitItem.discountType,
                  unitDiscount: kitItem.unitDiscount || 0,
                }

                const kitItemLending =
                  kitItem.lendingProducts?.length > 0 ? kitItem.lendingProducts[0] : null

                if (kitItemLending) {
                  return [
                    saleKitItem,
                    {
                      skuAssociatedId: saleKitItem.skuId,
                      id: kitItemLending.id,
                      quotationItemAssociatedId: item.quotationItemAssociatedId,
                      skuId: kitItemLending.skuProduct.skuId,
                      productKitId: itemSkuProduct.id,
                      quantity: kitItemLending.quantity,
                      unitValue: kitItemLending.price,
                      priceTableId: kitItemLending.priceTable?.id,
                      contractualFine: kitItemLending.skuProduct?.contractualFine,
                      discountPriceTable: 0, // item do kit seta pro desconto da tabela de preço default.
                      discountType: kitItemLending.discountType,
                      unitDiscount: kitItemLending.unitDiscount || 0,
                      isDeleted: item.isDeleted,
                    },
                  ]
                }

                return [saleKitItem]
              })
              .flat()
          }

          return {
            skuId: itemSkuProduct.id,
            productKitId: null,
            quantity: item.quantity,
            unitValue: itemSkuProduct.price,
            priceTableId,
            discountPriceTable: _.isNil(item.discountPriceTable)
              ? itemSkuProduct.priceTable?.discount
              : item.discountPriceTable,
            discountType: item.discountType,
            unitDiscount: item.unitDiscount,
            promotionId: itemSkuProduct?.promotionId || null,
            skuTieredPriceId: item.skuTieredPriceId,
          }
        })
        .flat()

      const lendingProducts = products
        .filter(item => !!item.skuProduct.lendingProductAssociated)
        .map(item => {
          const lp = item.skuProduct.lendingProductAssociated

          return {
            skuId: lp.id,
            productKitId: null,
            skuAssociatedId: item.skuProduct.id,
            quotationItemAssociatedId: item.quotationItemAssociatedId,
            quantity: lp.quantity * item.quantity,
            unitValue: lp.price || 0,
            priceTableId,
            discountType: null,
            discountPriceTable: null,
            unitDiscount: null,
          }
        })

      let delivery = {}
      if (isDelivery) {
        delivery = {
          delivery: isDelivery,
          deliveryTax: deliveryData.deliveryTax,
          deliveryAddress: isDelivery ? deliveryAddress : null,
          deliveryFeeType: deliveryData.deliveryFeeType,
          deliveryTaxDistance: deliveryData.distanceValue,
          deliveryTaxUnitValue:
            deliveryData.deliveryFeeType === deliveryDomain.data().deliveryFeeEnum.FEE_PER_KILOMETER
              ? deliveryData.shippingFeePerKilometer
              : deliveryData.shippingFee,
        }
      }

      const body = {
        id,
        storeId,
        priceTableId,
        customerId: customer.id,
        discountType,
        discount,
        items: [...items, ...lendingProducts],
        itemKits: kit.map(k => ({
          productKitId: k.skuProduct.id,
          unitValue: k.skuProduct.price,
          quantity: k.quantity,
        })),
        consigned: isConsigned,
        ...delivery,
      }

      await axios({
        url: '/api/sales/quotations',
        method: 'POST',
        data: body,
      })
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
    cleanDeliveryAddress({ commit }) {
      commit('CLEAN_DELIVERY_ADDRESS')
    },
    cleanInputsAuth({ commit }) {
      // TODO melhoria: verificar se usuário que autorizou campos na loja possui permissão na outra loja.
      commit('CLEAN_INPUTS_AUTH')
    },
    cleanCustomer({ commit }) {
      commit('CLEAN_CUSTOMER')
    },
  },
}
