<template>
  <div class="global-keyboard">
    <div
      ref="keyboardContainer"
      class="simple-keyboard"
    />
  </div>
</template>

<script>
import Keyboard from "simple-keyboard"
import "simple-keyboard/build/css/index.css"
import { mapActions, mapState } from "vuex"

export default {
  name: "SimpleKeyboard",

  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String
    },
    type: {
      type: String,
      default: 'mobile',
      validator(value) {
        return ['numeric', 'numericDecimal', 'default', 'mobile'].includes(value)
      },
    }
  },

  data: () => ({
    keyboard: null
  }),

  computed: {
    ...mapState('app/simpleKeyboard', {
      stActiveElement: 'activeElement',
      stSelectAllText: 'selectAllText'
    })
  },

  mounted() {
    const keyboardConfig = {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      preventMouseDownDefault: true, // evita de tirar o foco do input qnd digitar no simple-keyboard
      ...this.getLayout()
    }

    this.keyboard = new Keyboard(keyboardConfig)
    document.addEventListener('touchstart', this.handleHideKeyboard)

    this.setInput(this.stActiveElement?.value || '')
    this.setCaretPosition(this.stSelectAllText)
  },

  destroyed() {
    document.removeEventListener('touchstart', this.handleHideKeyboard)
    if (this.keyboard?.destroy) this.keyboard.destroy()
  },

  methods: {
    ...mapActions('app/simpleKeyboard', ['hideKeyboard']),

    handleHideKeyboard(event) {
      if (this.stActiveElement.contains(event.target)) {
        return
      }

      if (!this.$refs.keyboardContainer.contains(event.target)) {
        this.hideKeyboard()
      }
    },

    /** inputValue is a value in Keyboard instance, if you want update the value in instance need use this.keyboard.setInput() */
    onChange(inputValue) {
      this.$emit("input", inputValue)
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button)
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift()
      if (button === "{numbers}" || button === "{abc}") this.handleNumbers()
      if (button === "{enter}") this.handleEnter()
    },

    setInput(input) {
      this.keyboard.setInput(input)
    },

    setCaretPosition(isSelectAll) {
      const pos = this.stActiveElement?.value?.length

      if (pos > 0 && this.stActiveElement.setSelectionRange) {
        const inputType = this.stActiveElement.type
        const notSupportSelectRange = ['number'].includes(inputType)

        if (isSelectAll) {
          if (notSupportSelectRange) {
            this.stActiveElement.select()
          } else {
            this.stActiveElement.setSelectionRange(0, pos)
          }
        }
        else {
          this.stActiveElement.setSelectionRange(pos, pos)
        }
      }
    },

    handleShift() {
      const currentLayout = this.keyboard.options.layoutName
      const shiftToggle = currentLayout === "default" ? "shift" : "default"

      this.keyboard.setOptions({
        layoutName: shiftToggle
      })
    },
    handleNumbers() {
      const currentLayout = this.keyboard.options.layoutName
      const numbersToggle = currentLayout !== "numbers" ? "numbers" : "default"

      this.keyboard.setOptions({
        layoutName: numbersToggle
      })
    },
    handleEnter() {
      if (this.stActiveElement) this.stActiveElement.blur()
      this.hideKeyboard()
    },

    getLayout() {
      if (this.type === 'numeric' || this.type === 'numericDecimal') {
        const decimalChar = this.type === 'numericDecimal' ? ' , ' : ''
        return {
          theme: "hg-theme-default mobile-theme keyboardDefContainer",
          layout: {
            default: [
              "1 2 3",
              "4 5 6",
              "7 8 9",
              `{bksp} 0${decimalChar} {enter}`
            ]
          },
          display: {
            "{bksp}": "⌫",
            "{enter}": "Ok"
          }
        }
      }

      if (this.type === 'mobile') {
        return {
          mergeDisplay: true,
          theme: "hg-theme-default mobile-theme keyboardDefContainer",
          layout: {
            default: [
              "q w e r t y u i o p",
              "a s d f g h j k l",
              "{shift} . @ z x c v b n m {bksp}",
              "{numbers} {space} {enter}"
            ],
            shift: [
              "Q W E R T Y U I O P",
              "A S D F G H J K L",
              "{shift} Z X C V B N M {bksp}",
              "{numbers} {space} {enter}"
            ],
            numbers: [
              "1 2 3",
              "4 5 6",
              "7 8 9",
              "{abc} 0 {bksp} {enter}"
            ]
          },
          display: {
            "{numbers}": "123",
            "{enter}": "Ok",
            "{escape}": "esc ⎋",
            "{tab}": "tab ⇥",
            "{bksp}": "⌫",
            "{capslock}": "caps lock ⇪",
            "{shift}": "⇧",
            "{controlleft}": "ctrl ⌃",
            "{controlright}": "ctrl ⌃",
            "{altleft}": "alt ⌥",
            "{altright}": "alt ⌥",
            "{metaleft}": "cmd ⌘",
            "{metaright}": "cmd ⌘",
            "{abc}": "ABC"
          }
        }
      }

      return {
        mergeDisplay: true,
        layout: {
          default: [
            "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
            "{tab} q w e r t y u i o p [ ] \\",
            "{lock} a s d f g h j k l ; '",
            "{shift} z x c v b n m , . / {shift}",
            ".com @ {space} {enter}"
          ],
          shift: [
            "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
            "{tab} Q W E R T Y U I O P { } |",
            '{lock} A S D F G H J K L : "',
            "{shift} Z X C V B N M < > ? {shift}",
            ".com @ {space} {enter}"
          ],
        },
        display: {
          "{enter}": "Ok",
          "{bksp}": "⌫",
        }
      }
    }
  }
}
</script>

<style scoped>
.global-keyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 90000;
}
</style>
