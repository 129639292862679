<template>
  <section class="idle-screen">
    <div
      v-if="hasBanner"
      class="banner-carousel"
    >
      <div
        v-for="(banner, index) in getBannerForIdle"
        :key="`banner_idle-${index}`"
        :class="['banner-item', getPositionClass(index)]"
      >
        <img :src="banner.image">
      </div>
    </div>

    <div v-else>
      <b-img
        id="logo"
        :src="echopeLogo"
        alt="logo"
        fluid
        class="rounded"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import echopeLogo from '@/assets/images/logo/echope_horizontal_large.png'
import { BImg } from 'bootstrap-vue'
import { getInitialAtmCatalogFilters } from '@/store/pages/pdv/atm/atm-catalog'

export default {
  components: { BImg },
  data() {
    return {
      echopeLogo,
      bannerInterval: null,
      currentIndex: 0,
    }
  },

  computed: {
    ...mapState('app/idle', ['idleState']),
    ...mapGetters('pages/pdv/atm', ['getBannersAtm']),

    getBannerForIdle() {
      return this.getBannersAtm
    },

    hasBanner() {
      return this.getBannerForIdle.length > 0
    }
  },

  mounted() {
    this.bannerInterval = setInterval(this.changeBanner, this.idleState.bannerTime)
  },

  destroyed() {
    clearInterval(this.bannerInterval)
    this.stSetFiltersAndSearch(getInitialAtmCatalogFilters())
  },

  methods: {
    ...mapActions('pages/pdv/atm/catalog', {
      stSetFiltersAndSearch: 'setFiltersAndSearch'
    }),

    changeBanner() {
      this.currentIndex += 1
      if (this.currentIndex > (this.getBannerForIdle.length - 1)) {
        this.currentIndex = 0
      }
    },

    getPositionClass(index) {
      // if (index === this.currentIndex) return 'banner-center'
      // if (index === (this.currentIndex + 1) % this.getBannerForIdle.length) return 'banner-right'
      // return 'banner-left'

      if (index === this.currentIndex) return 'banner-show'

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.idle-screen {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background-color: #222;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 90001;
}


.banner-carousel {
  width: 100%;
  height: 100%;
  position: relative;

  .banner-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;
    transition: all 3s ease-in-out;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }
  }

  .banner-show {
    opacity: 1;
  }

  // .banner-left {
  //   transform: translateX(-100%);
  //   opacity: 0;
  // }

  // .banner-center {
  //   transform: translateX(0);
  //   opacity: 1;
  // }

  // .banner-right {
  //   transform: translateX(100%);
  //   opacity: 0;
  // }
}
</style>
