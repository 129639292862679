<template>
  <div>
    <input
      v-model="context.model"
      v-bind="context.attributes"
      readonly
      :input-class="context.attributes.class"
      :data-type="context.type"
      @blur="onBlur"
      @focus="onFocus"
      @touchstart="onTouchStart"
    >
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {

  components: {},

  props: {
    context: {
      type: Object,
      required: true,
    },
    oskType: {
      type: String,
      default: 'default',
    }
  },

  data: () => ({}),

  computed: {
    ...mapState('app/simpleKeyboard', ['currentInputValue']),
    model() {
      return this.context.model
    },
  },

  watch: {
    currentInputValue(newValue) {
      if (this.$el.contains(document.activeElement)) {
        this.context.model = newValue
      }
    }
  },

  methods: {
    ...mapActions('app/simpleKeyboard', ['showKeyboard', 'hideKeyboard']),

    // necessário para impedir teclado nativo
    onTouchStart(event) {
      event.preventDefault()

      this.showKeyboard({
        el: event.target,
        layoutType: this.oskType,
      })
    },

    onFocus(event) {
      this.showKeyboard({
        el: event.target,
        layoutType: this.oskType
      })
    },

    onBlur(event) {
      this.context.blurHandler(event)

      this.hideKeyboard()
      this.$emit('blur', this.model, event)
    },
  }
}
</script>
