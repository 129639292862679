import { storePagination } from '@/mixins/store'
import axios from '@axios'
import moment from 'moment'
import { sorting } from '@/mixins'
import orderMaintain from './order-maintain'

const getInitialState = () => ({
  ...storePagination.state(),
  orders: [],
  summaryFields: null,
  sorting: {
    sortBy: 'createdDate',
    sortDesc: true,
  },
  filters: {
    quotationId: '',
    saleId: null,
    storeId: null,
    customerId: null,
    priceTableId: null,
    skuId: null,
    isDelivery: null,
    isConsigned: null,
    status: ['InProgress'],
    deliveryStatus: [],
    echopeClassification: null,
    origin: null,
    periodType: 'created',
    rangeDate: {
      startDate: moment().add(-30, 'days').startOf('day').format(),
      endDate: moment().endOf('day').format(),
    },
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  modules: { orderMaintain },

  getters: {},

  mutations: {
    ...storePagination.mutations,

    SET_ORDERS(state, orders) {
      state.orders = orders
    },

    SET_FILTERS(state, filters) {
      state.filters = filters
    },

    SET_SUMMARY(state, summaryFields) {
      state.summaryFields = summaryFields
    },

    CLEAN_STATE(state) {
      const { paging, orders, filters, summaryFields } = getInitialState()
      state.orders = orders
      state.paging = paging
      state.filters = filters
      state.summaryFields = summaryFields
    },
  },

  actions: {
    ...storePagination.actions,

    async fetchOrders({ commit, state }) {
      const {
        saleId,
        quotationId,
        storeId,
        customerId,
        priceTableId,
        skuId,
        isDelivery,
        isConsigned,
        periodType,
        rangeDate,
        status,
        deliveryStatus,
        echopeClassification,
        origin,
      } = state.filters

      const { data } = await axios.get('/api/sales/orders', {
        params: {
          id: saleId,
          quotationId,
          storeId,
          customerId,
          priceTableId,
          skuId,
          isDelivery,
          isConsigned,
          status,
          deliveryStatus,
          echopeClassification,
          origin,
          periodType,
          // The range filter won't be applied when the sale id is informed
          dateFrom: saleId ? undefined : rangeDate.startDate,
          dateTo: saleId ? undefined : rangeDate.endDate,
          pageSize: state.paging.pageSize,
          pageIndex: state.paging.currentPage - 1,
          sortBy: sorting.methods.getSorting(state),
        },
      })
      commit('SET_ORDERS', data.results || [])
      commit('SET_PAGING', {
        ...state.paging,
        rowCount: data.rowCount,
        rowsInCurrentPage: data.rowsInCurrentPage,
      })
      commit('SET_SUMMARY', data.summaryFields)
    },

    async fetchSaleById(states, id) {
      const { data } = await axios.get(`/api/sales/${id}`)

      // TODO: Duplicated with src/store/pages/sale/sale/index.js :: fetchSaleById
      const dataConverted = {
        ...data,
        total: data.netValue,
        customer: {
          ...data.customer,
          name: data.customer.name,
          document: data.customer.document || '',
          telephone: data.customer.telephone || '',
        },
      }

      return dataConverted
    },

    async checkPaymentStatus(states, id) {
      const { data } = await axios.get(`/api/sales/payment/pix/status/${id}`)

      return data?.status
    },

    // TODO: possible duplicated with src/store/pages/sale/sale/index.js :: printOrder
    async printOrder({ rootGetters }, { orderPrint, template }) {
      const thermalPrinterAgentConfigPayload =
        rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

      if (
        orderPrint.status === 'InProgress' ||
        !orderPrint?.invoice ||
        orderPrint?.invoice?.model === 'NFE' ||
        template === 'LENDING_CONTRACT' ||
        template === 'RENT_CONTRACT' ||
        template === 'SALE_RENT' ||
        template === 'SALE_RETURN'
      ) {
        let saleItems
        if (template === 'LENDING_CONTRACT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Lending')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.contractualFine,
              totalValue: i.contractualFine * i.quantity,
              returnDateLimit: i.returnDateLimit,
            }))
        } else if (template === 'RENT_CONTRACT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Rent')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.contractualFine,
              totalValue: i.contractualFine * i.quantity,
              returnDateLimit: i.returnDateLimit,
            }))
        } else if (template === 'SALE_RENT') {
          saleItems = orderPrint.items
            .filter(i => i.classification === 'Rent')
            .map(i => ({
              skuId: i.sku.id,
              name: i.sku.name,
              printableName: i.sku.product?.printableName,
              quantity: i.quantity,
              unitValue: i.unitValue,
              totalValue: i.netValue,
              totalGrossValue: i.grossValue,
              returnDateLimit: i.returnDateLimit,
            }))
        } else if (template === 'SALE_RETURN') {
          saleItems = orderPrint.itemsReturned.map(i => ({
            skuId: i.sku.id,
            name: i.sku.name,
            printableName: i.sku.product?.printableName,
            quantity: i.quantity,
            unitValue: i.unitValue,
            totalValue: i.netValue,
            totalGrossValue: i.grossValue,
          }))
        } else {
          saleItems = orderPrint.items.map(i => ({
            skuId: i.sku.id,
            name: i.sku.name,
            printableName: i.sku.product?.printableName,
            quantity: i.quantity,
            unitValue: i.unitValue,
            totalValue: i.netValue,
            totalGrossValue: i.grossValue,
            choppGiftCardId: i.choppGiftCardId,
          }))
        }

        // TODO AVALIAR SE FAZ SENTIDO PEGAR LOCAL
        const { data: store } = await axios.get(`/api/stores/${orderPrint.store.id}`)

        const { data: customer } = await axios.get(`/api/customers/${orderPrint.customer.id}`)

        let additionalInformationInvoice = ''
        if (orderPrint?.invoice?.model === 'NFE') {
          additionalInformationInvoice = 'Dados da NF-e gerada para a venda: \n'
          additionalInformationInvoice += `Numero: ${orderPrint?.invoice?.invoiceNumber} \n`
          additionalInformationInvoice += `Chave de Acesso: ${orderPrint?.invoice?.nfeDetail?.accessKey} \n`
          additionalInformationInvoice += `Serie: ${orderPrint?.invoice?.serialNumber} \n`
        }

        const payload = {
          id: orderPrint.id,
          issueDate: orderPrint.issueDate,
          store: {
            id: store.id,
            document: store.cnpj,
            companyName: store.companyName,
            address: store?.address,
          },
          customer: {
            id: customer.id,
            cpf: customer.document,
            rg: customer.stateRegistration,
            name: customer.name,
            addressPublicPlace: customer?.addresses[0]?.publicPlace,
            addressNumber: customer?.addresses[0]?.number,
            addressNeighborhood: customer?.addresses[0]?.neighborhood,
            city: customer?.addresses[0]?.city,
            province: customer?.addresses[0]?.province,
          },
          totalValue: orderPrint.total,
          discount: orderPrint.discount + (orderPrint?.deliveryTaxDiscountValue || 0),
          isDelivery: orderPrint.delivery,
          deliveryCity: orderPrint?.deliveryAddress?.city,
          deliveryComplement: orderPrint?.deliveryAddress?.complement,
          deliveryDate: orderPrint?.deliveryDate,
          deliveryNeighborhood: orderPrint?.deliveryAddress?.neighborhood,
          deliveryNumber: orderPrint?.deliveryAddress?.number,
          deliveryObservation: orderPrint?.deliveryObservation,
          deliveryProvince: orderPrint?.deliveryAddress?.province,
          deliveryPublicPlace: orderPrint?.deliveryAddress?.publicPlace,
          deliveryZipCode: orderPrint?.deliveryAddress?.zipcode,
          deliveryTax: orderPrint?.deliveryTax,
          items: saleItems,
          additionalInformation: additionalInformationInvoice,
        }

        if (template === 'SALE_RETURN') {
          payload.totalValue = orderPrint.returnSale.value
          payload.issueDate = orderPrint.completedDate
          payload.discount = 0
          payload.isDelivery = false
          payload.deliveryTax = 0
        }

        await window.electronAPI.print({
          ...thermalPrinterAgentConfigPayload,
          request: {
            printTexts: [
              {
                printText: JSON.stringify(payload),
                template,
              },
            ],
          },
        })
      } else if (orderPrint?.invoice?.model === 'SAT') {
        const print = {
          status: orderPrint?.invoice.status,
          issueDate: orderPrint?.invoice?.issueDate,
          accessKey: orderPrint?.invoice?.satDetail?.cfeAccessKey,
          fileName: orderPrint?.invoice?.satDetail?.cfeXmlPath,
          accessKeyCancelled: orderPrint?.invoice?.satDetail?.cfeAccessKeyCancelled,
          fileNameCancelled: orderPrint?.invoice?.satDetail?.cfeXmlPathCancelled,
        }

        const printRequest = {
          ...thermalPrinterAgentConfigPayload,
          request: JSON.stringify(print),
        }

        await window.electronAPI.invoice.satPrint({ ...printRequest })
      } else if (orderPrint?.invoice?.model === 'NFCE') {
        const print = {
          fileName:
            orderPrint?.invoice?.status === 'ISSUED_IN_CONTINGENCY'
              ? orderPrint?.invoice?.nfeDetail?.xmlIssuedInContingencyPath
              : orderPrint?.invoice?.nfeDetail?.xmlFinishedSuccessPath,
          issueDate: orderPrint?.invoice?.issueDate,
          accessKey: orderPrint?.invoice?.nfeDetail?.accessKey,
          status: orderPrint?.invoice?.status,
          contingencyType: orderPrint?.invoice?.contingencyType,
        }

        const printRequest = {
          ...thermalPrinterAgentConfigPayload,
          request: JSON.stringify(print),
        }

        await window.electronAPI.invoice.nfcePrint({ ...printRequest })
      }
    },

    async printOrderExpedition({ rootGetters }, { orderPrint }) {
      const { expeditionThermalPrinter, thermalPrinterCustomerCopy } =
        rootGetters['pages/pdv/payBoxConfiguration/currentPayboxConfiguration']

      if (!expeditionThermalPrinter && !thermalPrinterCustomerCopy) {
        return
      }

      const saleItems = orderPrint.items.map(i => ({
        skuId: i.sku.id,
        name: i.sku.name,
        printableName: i.sku.product?.printableName,
        quantity: i.quantity,
        unitValue: i.unitValue,
        totalValue: i.netValue,
        totalGrossValue: i.grossValue,
      }))

      // TODO AVALIAR SE FAZ SENTIDO PEGAR LOCAL
      const { data: store } = await axios.get(`/api/stores/${orderPrint.store.id}`)
      const { data: customer } = await axios.get(`/api/customers/${orderPrint.customer.id}`)

      const payload = {
        id: orderPrint.id,
        issueDate: orderPrint.issueDate,
        store: {
          id: store.id,
          document: store.cnpj,
          companyName: store.companyName,
          address: store?.address,
        },
        customer: {
          id: customer.id,
          cpf: customer.document,
          rg: customer.stateRegistration,
          name: customer.name,
          addressPublicPlace: customer?.addresses[0]?.publicPlace,
          addressNumber: customer?.addresses[0]?.number,
          addressNeighborhood: customer?.addresses[0]?.neighborhood,
          city: customer?.addresses[0]?.city,
          province: customer?.addresses[0]?.province,
        },
        totalValue: orderPrint.total,
        discount: orderPrint.discount,
        isDelivery: orderPrint.delivery,
        deliveryCity: orderPrint?.deliveryAddress?.city,
        deliveryComplement: orderPrint?.deliveryAddress?.complement,
        deliveryDate: orderPrint?.deliveryDate,
        deliveryNeighborhood: orderPrint?.deliveryAddress?.neighborhood,
        deliveryNumber: orderPrint?.deliveryAddress?.number,
        deliveryObservation: orderPrint?.deliveryObservation,
        deliveryProvince: orderPrint?.deliveryAddress?.province,
        deliveryPublicPlace: orderPrint?.deliveryAddress?.publicPlace,
        deliveryZipCode: orderPrint?.deliveryAddress?.zipcode,
        deliveryTax: orderPrint?.deliveryTax,
        items: saleItems,
      }

      const printPromises = []

      if (thermalPrinterCustomerCopy) {
        const isPrintInItemTicketMode = rootGetters['pages/pdv/payBoxConfiguration/isPrintInItemTicketMode']
        if (isPrintInItemTicketMode) return

        const thermalPrinterAgentConfigPayload =
          rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterAgentConfigPayload']

        printPromises.push(
          window.electronAPI.print({
            ...thermalPrinterAgentConfigPayload,
            request: {
              printTexts: [
                { template: 'WITHDRAWAL_TO_CUSTOMER', printText: JSON.stringify(payload) },
              ],
            },
          })
        )
      }

      if (expeditionThermalPrinter) {
        const thermalPrinterExpeditionAgentConfigPayload =
          rootGetters['pages/pdv/payBoxConfiguration/thermalPrinterExpeditionAgentConfigPayload']

        printPromises.push(
          window.electronAPI.print({
            ...thermalPrinterExpeditionAgentConfigPayload,
            request: {
              printTexts: [
                { template: 'WITHDRAWAL_TO_EXPEDITION', printText: JSON.stringify(payload) },
              ],
            },
          })
        )
      }

      await Promise.all(printPromises)
    },

    resetFilters({ commit }) {
      commit('SET_FILTERS', getInitialState().filters)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
