import FormulateTextMask from './FormulateTextMask.vue'

export default function VueFormulateTextMaskPlugin(formulateInstance) {
  formulateInstance.extend({
    components: {
      FormulateTextMask,
    },
    library: {
      'text-mask': {
        classification: 'text',
        component: 'FormulateTextMask',
        slotProps: {
          component: ['inputMode'],
        },
      },
    },
  })
}
