var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "idle-screen" }, [
    _vm.hasBanner
      ? _c(
          "div",
          { staticClass: "banner-carousel" },
          _vm._l(_vm.getBannerForIdle, function (banner, index) {
            return _c(
              "div",
              {
                key: "banner_idle-" + index,
                class: ["banner-item", _vm.getPositionClass(index)],
              },
              [_c("img", { attrs: { src: banner.image } })]
            )
          }),
          0
        )
      : _c(
          "div",
          [
            _c("b-img", {
              staticClass: "rounded",
              attrs: {
                id: "logo",
                src: _vm.echopeLogo,
                alt: "logo",
                fluid: "",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }