var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "global-keyboard" }, [
    _c("div", { ref: "keyboardContainer", staticClass: "simple-keyboard" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }