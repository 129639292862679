import axios from '@axios'
import { uploader } from '@/mixins'
import _ from 'lodash'

export const getInitialPurchaseOrderItem = () => ({
  id: null,
  erpSku: {
    quantity: 0,
    sku: null,
    validationAvgPrice: {
      minValue: null,
      maxValue: null,
    },
    price: null,
    averagePrice: null,
  },
  unitValue: 0,
  totalValue: 0,
  itemNumber: 0,
})

export const getInitialPurchaseOrderPayment = () => ({
  id: null,
  billNumber: '',
  dueDate: null,
  installment: 0,
  managementAccount: null,
  managementAccountId: null,
  paymentMethod: null,
  paymentMethodId: null,
  value: 0,
})

export const getInitialPurchaseOrder = () => ({
  id: null,
  loaded: false,
  originalSupplier: {},
  supplier: {},
  supplierId: null,
  store: {},
  storeId: null,
  items: [],
  payments: [],
  invoiceKey: '',
  invoiceModel: 0,
  invoiceNumber: 0,
  invoiceSeries: 0,
  invoiceDate: null,
  productsValue: 0,
  freight: 0,
  insurance: 0,
  discount: 0,
  totalTaxes: 0,
  taxes: null,
  otherCosts: 0,
  totalValue: 0,
  status: 'Draft',
})

const getInitialState = () => ({
  invoiceXmlFile: [],
  store: null,
  purchaseOrder: getInitialPurchaseOrder(),
  lastApprovalFlow: null,
})

export default {
  namespaced: true,

  state: getInitialState(),

  getters: {
    isEdit(state) {
      return state.purchaseOrder.status !== 'Completed'
    },
    managementAccountsOptions(state) {
      return state.managementAccounts?.map(item => ({
        ...item,
        value: item.id,
        label: item.name,
      }))
    },
    readOnly(state) {
      return (
        ['Completed', 'Submitted', 'IssuerInvoiceCancelled'].indexOf(state.purchaseOrder.status) >=
        0
      )
    },
    approvalMessage(state) {
      if (!state.lastApprovalFlow) {
        return null
      }

      const approvalsArray = []
      state.lastApprovalFlow.steps.forEach(step => {
        // Para cada step, percorra cada approvalGroup
        step.approvalGroups.forEach(approvalGroup => {
          // Para cada approvalGroup, adicione todos os objetos dentro de "approvals" ao array final
          approvalsArray.push(...approvalGroup.approvals)
        })
      })
      const approvalWithMessage = approvalsArray.find(approval =>
        ['ChangesRequested', 'Disapproved'].includes(approval.status)
      )
      return approvalWithMessage?.observation
    },
  },

  mutations: {
    SET_INVOICE_XML_FILE(state, file) {
      state.invoiceXmlFile = file
    },
    SET_PURCHASE_ORDER(state, val) {
      state.purchaseOrder = val
      state.purchaseOrder.originalSupplier = val.supplier
      state.purchaseOrder.loaded = true
    },
    SET_LAST_APPROVAL_FLOW(state, val) {
      state.lastApprovalFlow = val
    },
    SET_ITEMS_PURCHASE(state, itemsPurchase) {
      state.purchaseOrder.items = itemsPurchase
    },
    SET_SUPPLIER(state, supplier) {
      state.supplier = supplier
    },
    SET_STORE(state, store) {
      state.store = store
    },
    SET_COMBO_SUPPLIER_ADDRESS(state, addresses) {
      state.combos.supplierAddress = addresses
    },

    UPDATE_ITEM(state, { index, item }) {
      state.itemsPurchase.items.splice(index, 1, {
        ...state.itemsPurchase.items[index],
        ...item,
      })
    },

    UPDATE_ASSOCIATED_SKU(state, { sku, itemIndex }) {
      state.purchaseOrder.items[itemIndex].erpSku.sku = sku
      state.purchaseOrder.items[itemIndex].erpSku.skuInputMethod = 'New'
      const itemsWithSameEan = state.purchaseOrder.items.filter(
        item => item.xmlProduct.eanTaxed === sku.ean && !item.erpSku.sku?.id
      )
      itemsWithSameEan.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.erpSku.sku = sku
      })
    },

    ADD_PAYABLE(state, payable) {
      if (typeof payable === 'object') {
        state.accountPayable.payables.push(payable)
      }
    },
    REMOVE_PAYABLE(state, index) {
      state.accountPayable.payables.splice(index, 1)
    },
    RESET_PAYMENT_INPUT(state, { uuid, indexInput }) {
      const payGroupIndex = state.accountPayable.payables.findIndex(p => p.uuid === uuid)
      if (payGroupIndex > -1) {
        state.accountPayable.payables[payGroupIndex].data[indexInput].value = 0
      }
    },

    CLEAN_PAYABLE_PARAMS(state) {
      const { accountPayable } = getInitialState()
      state.accountPayable.payableParams = accountPayable.payableParams
    },
    CLEAN_STATE_TABS(state) {
      const { itemsPurchase, accountsPayable, supplier, combos, lastApprovalFlow } =
        getInitialState()
      state.itemsPurchase = itemsPurchase
      state.accountsPayable = accountsPayable
      state.supplier = supplier
      state.combos = combos
      state.lastApprovalFlow = lastApprovalFlow
    },
    CLEAN_FILE(state) {
      const { invoiceXmlFile, xmlFileLoaded, purchaseOrder } = getInitialState()
      state.invoiceXmlFile = invoiceXmlFile
      state.xmlFileLoaded = xmlFileLoaded
      state.purchaseOrder = purchaseOrder
    },
  },

  actions: {
    async sendXmlInvoice({ commit, dispatch }, { file, storeId }) {
      const itemDefault = getInitialPurchaseOrderItem()

      const result = await uploader.methods.uploadCustom({
        file,
        apiUrl: '/api/purchases/purchase-orders/read-xml',
        params: { storeId },
        fileParamName: 'file',
      })
      result.items = result.items.map(item => ({
        ...item,
        erpSku: {
          ...itemDefault.erpSku,
          ...item.erpSku,
        },
      }))
      result.payments = result.payments.map(payment => ({
        ...payment,
        paymentMethodId: payment.paymentMethodId?.toString(),
      }))
      // const result = await axios.post(`/api/purchases/purchase-orders/read-xml`, file)
      commit('SET_PURCHASE_ORDER', result)
      await dispatch('loadItemsAveragePrice')
    },

    async fetchPurchaseOrderById(states, id) {
      const { data } = await axios.get(`/api/purchases/purchase-orders/${id}`)
      data.payments = data.payments.map(payment => ({
        ...payment,
        paymentMethodId: payment.paymentMethodId?.toString(),
      }))

      return data
    },

    async fetchPurchaseOrder({ commit, dispatch, getters }, id) {
      const data = await dispatch('fetchPurchaseOrderById', id)
      commit('SET_PURCHASE_ORDER', data)
      commit('SET_INVOICE_XML_FILE', uploader.methods.getFileInstance(data.invoiceXmlUrl))

      if (getters.isEdit) {
        await dispatch('loadItemsAveragePrice')
      }
    },

    async fetchPurchaseOrderLastApprovalFlow({ commit }, id) {
      try {
        const { data } = await axios.get(`/api/purchases/purchase-orders/${id}/last-approval-flow`)
        commit('SET_LAST_APPROVAL_FLOW', data)
      } catch (err) {
        console.log(err)
      }
    },

    async savePurchaseOrder(
      { state },
      { itemsUnitValueRevision = false, file = null, tokenDelegated } = {}
    ) {
      const axiosOptions = {}
      if (itemsUnitValueRevision) {
        axiosOptions.headers = {
          'delegate-token': tokenDelegated,
        }
      }

      let body = {
        ...state.purchaseOrder,
        supplier: state.purchaseOrder.originalSupplier,
        itemsUnitValueRevision,
      }

      if (file) {
        const formData = new FormData()
        formData.append('purchaseOrder', JSON.stringify(body))
        formData.append('file', file.file)

        body = formData
      }

      const { data } = await axios({
        url: '/api/purchases/purchase-orders',
        method: state.purchaseOrder?.id ? 'PUT' : 'POST',
        data: body,
        ...axiosOptions,
      })

      return data
    },

    async reevaluatePurchaseOrder(
      { state },
      { itemsUnitValueRevision = false, tokenDelegated } = {}
    ) {
      const axiosOptions = {}
      if (itemsUnitValueRevision) {
        axiosOptions.headers = {
          'delegate-token': tokenDelegated,
        }
      }

      const body = {
        ...state.purchaseOrder,
        supplier: state.purchaseOrder.originalSupplier,
      }

      await axios({
        url: '/api/purchases/purchase-orders/reevaluate',
        method: 'PUT',
        data: { ...body, itemsUnitValueRevision },
        ...axiosOptions,
      })
    },

    async reversePurchaseOrder(context, purchaseOrderId) {
      await axios.put(`/api/purchases/purchase-orders/${purchaseOrderId}/reverse`)
    },

    async loadItemsAveragePrice({ state, commit, dispatch }) {
      try {
        if (state.purchaseOrder.items.length === 0) return

        const itemsResult = await dispatch('prepareItemsAveragePrice', {
          items: state.purchaseOrder.items,
          storeId: state.purchaseOrder.store?.id,
        })

        commit('SET_ITEMS_PURCHASE', itemsResult)
      } catch (err) {
        console.error(err)
      }
    },
    async prepareItemsAveragePrice(context, { items, storeId, toUpdateAll = false }) {
      const itensFiltered = toUpdateAll ? items : items.filter(i => _.isNil(i.erpSku?.price))
      const params = {
        skuIds: itensFiltered.map(i => i.erpSku?.sku?.id),
      }
      const { data: skusRes } = await axios.get(`/api/skus/store/${storeId}/prices`, { params })

      const getMinMaxAvgValues = value => {
        const avgCost = value || 0
        const variantPercent = 0.05
        const variantValue = avgCost * variantPercent

        return {
          minValue: avgCost - variantValue,
          maxValue: avgCost + variantValue,
        }
      }

      const itemsResult = items.map(i => {
        if (!toUpdateAll && !_.isNil(i.erpSku?.price)) return i

        const avgItem = skusRes.find(sku => sku.id === i.erpSku?.sku?.id)
        if (!avgItem) return i

        const { minValue, maxValue } = getMinMaxAvgValues(avgItem.averageCost)

        return {
          ...i,
          erpSku: {
            ...i.erpSku,
            price: avgItem.price,
            averagePrice: avgItem.averageCost,
            validationAvgPrice: {
              minValue,
              maxValue,
            },
          },
        }
      })

      return itemsResult
    },

    setInvoiceXmlFile({ commit }, file) {
      commit('SET_INVOICE_XML_FILE', file)
    },

    setStore({ commit }, store) {
      commit('SET_STORE', store)
    },

    updateItem({ commit }, { index, item }) {
      if (typeof item !== 'object') throw new Error('Item is not a object')
      else if (typeof index !== 'number' || index < 0) throw new Error('index is not valid')

      commit('UPDATE_ITEM', { index, item })
    },

    updateAssociatedSku({ commit }, { sku, itemIndex }) {
      commit('UPDATE_ASSOCIATED_SKU', { sku, itemIndex })
    },

    addPayable({ commit, dispatch, state }, payable) {
      const valueExceedBalance =
        state.accountPayable.remainingBalance < state.accountPayable.payableParams.value
      if (valueExceedBalance)
        return { error: true, message: '<b>Valor é maior que o saldo restante</b>' }
      commit('ADD_PAYABLE', payable)
      dispatch('reloadRemainingBalance')
      return { error: false, message: '' }
    },
    removePayable({ commit, dispatch }, index) {
      if (index === undefined || index === -1 || index === null) return
      commit('REMOVE_PAYABLE', index)
      dispatch('reloadRemainingBalance')
    },
    reloadRemainingBalance({ commit, getters }) {
      const totalPayments = Number((getters.getTotalPayments || 0).toFixed(5))
      const total = Number((getters.getTotal || 0).toFixed(5))

      if (total < totalPayments) return false

      const actualBalance = total - totalPayments
      commit('SET_REMAINING_VALUES', actualBalance)
      return true
    },

    resetPaymentInput({ commit }, { uuid, indexInput }) {
      commit('RESET_PAYMENT_INPUT', { uuid, indexInput })
    },

    cleanPayableParams({ commit }) {
      commit('CLEAN_PAYABLE_PARAMS')
    },
    cleanStateTabs({ commit }) {
      commit('SET_PURCHASE_ORDER', getInitialState().purchaseOrder)
      commit('CLEAN_STATE_TABS')
    },
    cleanState({ commit }) {
      commit('CLEAN_FILE')
      commit('CLEAN_STATE_TABS')
    },
  },
}
