var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("formulate-text-mask", {
        attrs: {
          context: _vm.context,
          "input-mode": _vm.inputMode,
          readonly: "",
        },
        on: {
          focus: _vm.onFocus,
          blur: _vm.onBlur,
          touchstart: _vm.onTouchStart,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }