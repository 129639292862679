import store from '@/store'
import { toMiliseconds } from '@/utils/promise-util'
import isElectron from 'is-electron'

const eventsForIdle = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']
let idleTimer

function resetIdleTimer() {
  clearTimeout(idleTimer)
  const time = store.state.app?.idle?.idleState?.idleTime || toMiliseconds({ min: 1 })

  if (store.state.app?.idle?.idleState?.isIdle)
    store.dispatch('app/idle/setIsIdle', { isIdle: false })

  idleTimer = setTimeout(() => {
    if (!store.state.app?.idle?.idleState?.isIdle)
      store.dispatch('app/idle/setIsIdle', { isIdle: true })
  }, time)
}

const getInitialState = () => ({
  idleState: {
    isIdle: false,
    idleTime: toMiliseconds({ min: 1 }),
    bannerTime: toMiliseconds({ sec: 10 }),
  },
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    isIdle(state) {
      return state.idleState.isIdle
    },
  },

  mutations: {
    SET_IS_IDLE(state, isIdle) {
      state.idleState.isIdle = isIdle
    },

    CLEAN_STATE(state) {
      const defaultData = getInitialState()
      Object.keys(defaultData).forEach(key => {
        state[key] = defaultData[key]
      })
    },
  },

  actions: {
    setIsIdle({ commit }, { isIdle }) {
      commit('SET_IS_IDLE', isIdle)
    },

    async initIdle() {
      if (!isElectron()) return

      const payBoxData = await window.electronAPI.system.identifyPayboxRead()

      if (!payBoxData?.isAtm) return

      eventsForIdle.forEach(event => {
        document.addEventListener(event, resetIdleTimer, true)
      })
      resetIdleTimer()
    },
    destroyIdle() {
      eventsForIdle.forEach(event => {
        document.removeEventListener(event, this.resetIdleTimer, true)
      })
      clearTimeout(idleTimer)
    },

    async cleanState({ commit }) {
      commit('CLEAN_STATE')
    },
  },
}
