import Vue from 'vue'
import axios from '@axios'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import { statusTypes } from '@/mixins'
import _ from 'lodash'
import { formatTime } from '@/utils/dateUtils'
import { toMiliseconds } from '@/utils/promise-util'
import alarmAudio from '@/assets/audio/alarm.mp3'
import notificationAudio from '@/assets/audio/notification.mp3'

const notificationMessage = new Audio(notificationAudio)
const alarmSound = new Audio(alarmAudio)

const deliveryStatusEnum = statusTypes.computed.deliveryStatusEnum()
function playSoundNTimes({ sound, times }) {
  let count = 0

  // Function to play sound and increment count
  function playSound() {
    if (count < times) {
      // setTimeout(() => {
      sound
        .play()
        .then(() => {
          count += 1
          // Wait for the sound to finish before playing it again
          // eslint-disable-next-line no-param-reassign
          sound.onended = playSound
        })
        .catch(error => {
          console.error('Error playing sound:', error)
        })
      // }, interval || 1000)
    }
  }

  // Start playing sound
  playSound()
}

const getInitialState = () => ({
  filter: {
    storeId: null,
  },
  webSocket: {
    delivery: {
      config: null,
      ready: false,
      connected: false,
      ws: null,
      currentChannelId: null,
    },
    chat: {
      config: null,
      ready: false,
      connected: false,
      ws: null,
      currentChannelId: null,
    },
  },
  allOrders: [],
  chats: [],
  contacts: [],
  activeChat: null,
  lastFetch: null,
  orderItems: {},
  lastTimeHasUnansweredChats: null,
})

export default {
  namespaced: true,
  state: getInitialState(),

  getters: {
    ready(state) {
      return state.webSocket.delivery.ready && state.webSocket.chat.ready
    },
    chatWebSocketConnected(state) {
      return state.webSocket.chat.connected
    },
    deliveryWebSocketConnected(state) {
      return state.webSocket.delivery.connected
    },
    groupedChats(state) {
      return _.groupBy(
        state.activeChat?.messages,
        message => `${message.userId}.${formatTime(message.created)}`
      )
    },
    orders(state) {
      return state.allOrders.filter(r => r.deliveryStatus === deliveryStatusEnum.PENDING)
    },
    preparingOrders(state) {
      return state.allOrders.filter(r => r.deliveryStatus === deliveryStatusEnum.PREPARING)
    },
    deliveringOrders(state) {
      return state.allOrders.filter(r => r.deliveryStatus === deliveryStatusEnum.IN_PROGRESS)
    },
    deliveredOrders(state) {
      return state.allOrders.filter(r => r.deliveryStatus === deliveryStatusEnum.COMPLETED)
    },
    hasUnansweredChats(state) {
      return !!state.allOrders.find(order => order.hasChatPendingAnswer)
    },
  },

  mutations: {
    SET_CHAT_READY(state, ready) {
      state.webSocket.chat.ready = ready
    },
    SET_DELIVERY_READY(state, ready) {
      state.webSocket.delivery.ready = ready
    },
    SET_CHAT_WEBSOCKET_CONNECTED(state, connected) {
      state.webSocket.chat.connected = connected
    },
    SET_DELIVERY_WEBSOCKET_CONNECTED(state, connected) {
      state.webSocket.delivery.connected = connected
    },
    SET_FILTER(state, filter) {
      state.filter = filter
    },
    SET_ALL_ORDERS(state, orders) {
      state.allOrders = orders
    },
    SET_LAST_FETCHED(state) {
      state.lastFetch = moment()
    },

    SET_ORDER_ITEMS(state, { orderId, items }) {
      Vue.set(state.orderItems, orderId, items)
    },

    SET_CONTACTS(state, orders) {
      state.contacts = orders.map(order => {
        const [firstName] = order.customer.name.split(' ')
        return {
          id: order.id,
          fullName: `${firstName} - Ped. #${order.id}`,
          status: 'online',
          channelId: `order-${state.filter.storeId}-${order.customer.id}-${order.id}`,
          chat: {
            lastMessage: {
              time: Date.now,
            },
          },
        }
      })
    },

    CLEAN_STATE(state) {
      const defaulData = getInitialState()
      Object.keys(defaulData).forEach(key => {
        state[key] = defaulData[key]
      })
    },

    SET_OR_ADD_ORDER(state, orderPayload) {
      if (!orderPayload) return
      const existingOrderIndex = state.allOrders.findIndex(order => order.id === orderPayload.id)

      if (existingOrderIndex !== -1) {
        const existingOrder = state.allOrders[existingOrderIndex]

        if (state.orderItems[orderPayload.id] && orderPayload.itemsModified) {
          delete state.orderItems[orderPayload.id]
        }

        // Use moment to compare eventDate with modifiedDate to ensure only newer events are processed
        const isNewerEvent = moment(orderPayload.eventDate).isSameOrAfter(
          moment(existingOrder.modifiedDate)
        )

        if (isNewerEvent) {
          if (orderPayload.deliveryStatus === deliveryStatusEnum.CANCELED) {
            state.allOrders.splice(existingOrderIndex, 1)
          } else {
            Vue.set(state.allOrders, existingOrderIndex, {
              ...existingOrder,
              ...orderPayload,
            })
          }
        }
      } else if (orderPayload.deliveryStatus !== deliveryStatusEnum.CANCELED) {
        state.allOrders.push(orderPayload)
      }
    },

    SET_MESSAGE_STATUS(state, message) {
      const existingMessage = state.activeChat?.messages.find(
        messageItem => messageItem.messageId === message.messageId
      )
      if (existingMessage) {
        Vue.set(existingMessage, message.action, Date.now())
      }
    },

    ADD_CHAT_MESSAGE(state, message) {
      const currentChat = state.chats.find(chat => chat.channelId === message.channelId)
      console.log('ADD_CHAT_MESSAGE', message, currentChat)
      if (currentChat) {
        currentChat.lastMessage = message
        if (message.userId.indexOf('C-') === 0) {
          currentChat.hasChatPendingAnswer = true
          if (currentChat.channelId !== state.activeChat?.channelId) {
            currentChat.totalUnreadMessages = (currentChat.totalUnreadMessages || 0) + 1
          }
        } else {
          currentChat.hasChatPendingAnswer = false
        }
        currentChat.totalMessages = (currentChat.totalMessages || 0) + 1
      }

      if (state.activeChat?.channelId === message.channelId) {
        state.activeChat.messages.push(message)
      }

      const [orderId] = message.channelId.split('-').slice(-1)
      // eslint-disable-next-line eqeqeq
      const existingOrder = state.allOrders.find(order => order.id == orderId)
      if (existingOrder) {
        if (message.userId.indexOf('C-') === 0) {
          Vue.set(existingOrder, 'hasChatPendingAnswer', true)
          if (message.channelId !== state.activeChat?.channelId) {
            Vue.set(
              existingOrder,
              'totalUnreadMessages',
              (existingOrder.totalUnreadMessages || 0) + 1
            )
          }
          Vue.set(existingOrder, 'totalMessages', (existingOrder.totalMessages || 0) + 1)
        }
        Vue.set(existingOrder, 'hasChatPendingAnswer', message.userId.indexOf('C-') === 0)
      }
    },

    SET_ACTIVE_CHAT(state, chat) {
      state.activeChat = chat
    },

    SET_ACTIVE_CHATS(state, chats) {
      chats.forEach(chat => {
        const [orderId] = chat.channelId.split('-').slice(-1)
        // eslint-disable-next-line eqeqeq
        const existingOrder = state.allOrders.find(order => order.id == orderId)
        if (existingOrder) {
          Vue.set(existingOrder, 'totalUnreadMessages', chat.totalUnreadMessages)
          Vue.set(existingOrder, 'totalMessages', chat.totalMessages)
          Vue.set(existingOrder, 'chatActive', true)
          Vue.set(
            existingOrder,
            'hasChatPendingAnswer',
            chat.lastMessage?.userId?.indexOf('C-') === 0
          )
        }
      })
      state.chats = chats
    },

    SET_READ_MESSAGES(state, messages) {
      if (messages.length) {
        const [channelId] = messages.map(message => message.channelId)
        const existingChat = state.chats.find(chat => chat.channelId === channelId)
        const now = Date.now()
        messages.forEach(message => {
          let chatMessage = existingChat.messages?.find(
            chatMessageItem => chatMessageItem.messageId === message.messageId
          )
          if (chatMessage) {
            chatMessage.read = now
          }

          chatMessage = state.activeChat.messages?.find(
            chatMessageItem => chatMessageItem.messageId === message.messageId
          )
          if (chatMessage) {
            chatMessage.read = now
          }
        })
        if (existingChat) {
          existingChat.totalUnreadMessages = 0
        }
        const [orderId] = channelId.split('-').slice(-1)
        // eslint-disable-next-line eqeqeq
        const existingOrder = state.allOrders.find(order => order.id == orderId)
        if (existingOrder) {
          Vue.set(existingOrder, 'totalUnreadMessages', 0)
          Vue.set(existingOrder, 'chatActive', true)
        }
      }
    },

    ADD_CHAT(state, chat) {
      if (!state.chats.find(chatItem => chatItem.channelId === chat.channelId)) {
        state.chats.push(chat)
      }
    },

    SET_DELIVERY_WS_CONFIG(state, data) {
      state.webSocket.delivery.config = data
    },

    CLEAR_ACTIVE_CHAT(state) {
      state.activeChat = null
    },
  },

  actions: {
    async fetchDeliveryWebSocketConfig({ commit }) {
      const { data } = await axios.get('/api/settings/delivery/ws')
      commit('SET_DELIVERY_WS_CONFIG', data)
    },

    async fetchDeliveryOrders({ commit, state }) {
      const { storeId } = state.filter
      if (!storeId) return
      const { data } = await axios.get(`/api/sales/delivery/store/${storeId}`, {
        params: {
          pageSize: 0,
          pageIndex: 9999,
          sortBy: 'DeliveryDate ASC',
        },
      })

      commit('SET_LAST_FETCHED')
      commit('SET_CONTACTS', data.results)
      commit('SET_ALL_ORDERS', data.results)
      commit('SET_ACTIVE_CHATS', state.chats)
    },

    runUnansweredMessageAlarm({ state }) {
      setInterval(() => {
        if (
          state.lastTimeHasUnansweredChats &&
          Date.now() - state.lastTimeHasUnansweredChats >= 60000
        ) {
          playSoundNTimes({ sound: alarmSound, times: 5, interval: 2000 })
        }
      }, toMiliseconds({ min: 1 }))
    },

    setFilter({ commit }, filter) {
      commit('SET_FILTER', filter)
    },

    setOrderDeliveryStatus({ commit }, orderPayload) {
      commit('SET_OR_ADD_ORDER', orderPayload)
    },

    cleanState({ commit }) {
      commit('CLEAN_STATE')
    },

    connectToDeliveryWebSocket({ commit, state, dispatch }) {
      const { webSocketEndpoint } = state.webSocket.delivery.config
      console.debug('[delivery websocket] Connecting to', webSocketEndpoint)

      state.webSocket.delivery.ws = new WebSocket(`${webSocketEndpoint}?Token=${useJwt.getToken()}`)
      state.webSocket.delivery.ws.onerror = error => {
        commit('SET_DELIVERY_READY', true)
        commit('SET_DELIVERY_WEBSOCKET_CONNECTED', false)
        console.debug('[delivery websocket] Connection error', error)
      }

      state.webSocket.delivery.ws.onopen = () => {
        console.debug('[delivery websocket] Connection opened')
        dispatch('joinDeliveryChannel')
        commit('SET_DELIVERY_READY', true)
      }

      state.webSocket.delivery.ws.onmessage = event => {
        const data = JSON.parse(event.data)
        dispatch('handleIncomingDeliveryEvent', data)
      }

      state.webSocket.delivery.ws.onclose = () => {
        console.debug('[delivery websocket] Connection closed')
        commit('SET_DELIVERY_WEBSOCKET_CONNECTED', false)
        dispatch('connectToDeliveryWebSocket')
      }
    },

    async joinDeliveryChannel({ commit, state }) {
      if (state.webSocket.delivery.ws) {
        if (state.webSocket.delivery.currentChannelId) {
          const message = {
            action: 'leave',
            channelId: state.webSocket.delivery.currentChannelId,
          }

          console.debug('[delivery websocket] Leaving channel ', message.channelId)

          state.webSocket.delivery.ws.send(JSON.stringify(message))
        }

        if (state.filter.storeId) {
          const message = {
            action: 'join',
            channelId: `store-${state.filter.storeId}-sale-delivery-events`,
          }

          console.debug('[delivery websocket] Joining channel ', message.channelId)

          state.webSocket.delivery.ws.send(JSON.stringify(message))

          commit('SET_DELIVERY_WEBSOCKET_CONNECTED', true)

          state.webSocket.delivery.currentChannelId = message.channelId
        } else {
          commit('SET_DELIVERY_WEBSOCKET_CONNECTED', false)
        }
      }
    },

    handleIncomingDeliveryEvent({ commit, state }, data) {
      console.debug('[delivery websocket] receiving data', data)

      if (state.webSocket.delivery.currentChannelId !== data.channelId) {
        console.debug('[delivery websocket] received message from different channel, ignore', data)
        return
      }

      switch (data.action) {
        case 'sendMessage': {
          const orderEvent = JSON.parse(data.content)

          commit('SET_OR_ADD_ORDER', orderEvent)
          commit('SET_LAST_FETCHED')
          break
        }
        default:
      }
    },

    connectToChatWebSocket({ commit, state, rootState, dispatch }, tryNumber) {
      if (rootState.app.chatApiSettings.webSocketEndpoint) {
        console.log(
          '[chat websocket] Connecting to ',
          rootState.app.chatApiSettings.webSocketEndpoint
        )

        state.webSocket.chat.ws = new WebSocket(
          `${rootState.app.chatApiSettings.webSocketEndpoint}?Token=${useJwt.getToken()}`
        )
        state.webSocket.chat.ws.onerror = error => {
          commit('SET_CHAT_READY', true)
          commit('SET_CHAT_WEBSOCKET_CONNECTED', false)
          console.log('[chat websocket] Connection error', error)
        }

        state.webSocket.chat.ws.onopen = () => {
          console.log('[chat websocket] Connection opened')
          dispatch('joinChatChannel')
          commit('SET_CHAT_READY', true)
        }

        state.webSocket.chat.ws.onmessage = event => {
          const data = JSON.parse(event.data)
          dispatch('handleIncomingMessage', data)
        }

        state.webSocket.chat.ws.onclose = () => {
          console.log('[chat websocket] Connection closed')
          commit('SET_CHAT_WEBSOCKET_CONNECTED', false)
          dispatch('connectToChatWebSocket')
        }
      } else {
        console.log('[chat websocket] Websocket config not found')
        if ((tryNumber ?? 0) < 10) {
          setTimeout(() => {
            dispatch('connectToChatWebSocket', (tryNumber ?? 0) + 1)
          }, 1000)
        } else {
          commit('SET_CHAT_READY', true)
          commit('SET_CHAT_WEBSOCKET_CONNECTED', false)
        }
      }
    },

    handleIncomingMessage({ commit, state, dispatch }, data) {
      console.log('[chat websocket] receiving data', data)
      switch (data.action) {
        case 'message': {
          if (data.userId.indexOf('C-') === 0) {
            notificationMessage.play()
            const message = {
              ...data,
              action: data.channelId === state.activeChat?.channelId ? 'read' : 'delivered',
            }

            state.webSocket.chat.ws.send(JSON.stringify(message))
          }

          dispatch('addChatMessage', {
            ...data,
            userId: data.userId.replace(/^E-/i, ''),
          })
          break
        }
        case 'delivered':
        case 'read':
          commit('SET_MESSAGE_STATUS', data)
          break
        default:
      }
    },

    async joinChatChannel({ commit, state }) {
      if (state.webSocket.chat.ws) {
        let message

        if (state.activeChat?.channelId) {
          message = {
            action: 'leave',
            channelId: state.webSocket.chat.currentChannelId,
          }

          console.log('[chat websocket] Leaving channel ', state.webSocket.chat.currentChannelId)

          if (state.webSocket.chat.ws.readyState === WebSocket.OPEN) {
            state.webSocket.chat.ws.send(JSON.stringify(message))
          }
        }

        if (state.filter.storeId) {
          message = {
            action: 'join',
            channelId: `order-${state.filter.storeId}-*`,
          }

          console.log('[chat websocket] Joining channel ', message.channelId)

          if (state.webSocket.chat.ws.readyState === WebSocket.OPEN) {
            state.webSocket.chat.ws.send(JSON.stringify(message))
            commit('SET_CHAT_WEBSOCKET_CONNECTED', true)
            state.webSocket.chat.currentChannelId = message.channelId
          }
        } else {
          commit('SET_CHAT_WEBSOCKET_CONNECTED', false)
        }
      }
    },

    async startChat({ commit, state, rootState }, order) {
      let chat = state.chats.find(chatItem => chatItem.id === order.id)
      if (!chat) {
        // Create a new chat
        chat = {
          ...order,
          channelId: `order-${state.filter.storeId}-${order.id}`,
          messages: [],
          chat: {
            lastMessage: {
              time: Date.now(),
            },
          },
        }
      }

      try {
        chat.messages = (
          await axios.get(
            `${rootState.app.chatApiSettings.webApiEndpoint}/v1/channels/${chat.channelId}/messages`
          )
        ).data.map(message => ({
          ...message,
          userId: message.userId.replace(/^E-/i, ''),
        }))
      } catch (error) {
        chat.messages = []
        console.log('Error getting chat messages: ', error)
      }

      commit('ADD_CHAT', chat)
      commit('SET_ACTIVE_CHAT', chat)
    },

    quitChat({ commit }) {
      commit('CLEAR_ACTIVE_CHAT')
    },

    readUnreadMessages({ commit, state }) {
      const unreadMessages = state.activeChat.messages.filter(
        message => message.userId.indexOf('C-') === 0
      )
      unreadMessages.forEach(message => {
        const readMessage = message
        readMessage.action = 'read'
        state.webSocket.chat.ws.send(JSON.stringify(message))
      })
      commit('SET_READ_MESSAGES', unreadMessages)
    },

    async loadActiveChats({ commit, state, rootState }) {
      if (state.webSocket.chat.currentChannelId) {
        const activeChats = await axios.get(
          `${
            rootState.app.chatApiSettings.webApiEndpoint
          }/v1/channels/${state.webSocket.chat.currentChannelId.replace('*', '')}`
        )
        commit(
          'SET_ACTIVE_CHATS',
          activeChats.data?.map(chat => ({
            ...chat,
          }))
        )
      }
    },

    addChatMessage({ commit, state, dispatch }, message) {
      let currentChat = state.chats.find(chat => chat.channelId === message.channelId)
      if (!currentChat) {
        const [orderId] = message.channelId.split('-').slice(-1)
        // eslint-disable-next-line eqeqeq
        const order = state.allOrders.find(orderItem => orderItem.id == orderId)
        dispatch('startChat', order)
        currentChat = state.chats.find(chat => chat.channelId === message.channelId)
      }
      commit('ADD_CHAT_MESSAGE', message)
    },

    sendChatMessage({ commit, state, dispatch }, message) {
      let currentChat = state.chats.find(chat => chat.channelId === message.channelId)
      if (!currentChat) {
        const [orderId] = message.channelId.split('-').slice(-1)
        const order = state.allOrders.find(orderItem => orderItem.id === orderId)
        dispatch('startChat', order)
        currentChat = state.chats.find(chat => chat.channelId === message.channelId)
      }
      commit('ADD_CHAT_MESSAGE', message)
      state.webSocket.chat.ws.send(JSON.stringify(message))
    },

    editChatMessage({ state }, message) {
      const existingMessage = state.activeChat.messages.find(
        messageItem => messageItem.messageId === message.messageId
      )
      existingMessage.content = message.content
      state.webSocket.chat.ws.send(JSON.stringify(message))
    },

    deleteChatMessage({ state }, message) {
      const messageIndex = state.activeChat.messages.findIndex(
        messageItem => messageItem.messageId === message.messageId
      )
      state.activeChat.messages.splice(messageIndex, 1)
      state.webSocket.chat.ws.send(JSON.stringify(message))
    },

    async loadOrderItems({ commit, state }, orderId) {
      if (!state.orderItems[orderId]) {
        const items = (await axios.get(`/api/sales/${orderId}/items`)).data
        commit('SET_ORDER_ITEMS', { orderId, items })
      }
    },
    setLastTimeHasUnansweredChats({ state }, lastTime) {
      state.lastTimeHasUnansweredChats = lastTime
    },
  },
}
